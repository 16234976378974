import { ICareProgram } from './MemberCareTeam.interface'

export class CareTeamPayload {
	id?: string
	careTeam: { careTeam: string }[] | undefined
	patient: string | any
	constructor({ careTeam, patient }: Partial<CareTeamInitialState> = {}) {
		this.addCareTeam(careTeam)
		this.addPatient(patient)
	}
	addCareTeam(data: any) {
		const newCpt = data?.map((obj: any) => {
			return obj.value
		})

		this.careTeam = newCpt
	}

	addPatient(patient: string | undefined | null) {
		if (patient) {
			this.patient = patient
		}
	}
}

export class CareTeamInitialState {
	careTeam:
		| {
				careTeam: string | null
		  }[]
		| undefined
	patient: string | any
	constructor({ careTeam, patient }: Partial<ICareProgram> = {}) {
		this.addCareTeam(careTeam)
		this.addPatient(patient)
	}

	addCareTeam(careTeam: any) {
		if (careTeam) {
			const newArray = careTeam.map((obj: any) => ({
				value: obj.state.id,
				label: obj.state.state,
			}))
			this.careTeam = newArray
		}
	}

	addPatient(patient: string | undefined) {
		if (patient) {
			this.patient = patient
		}
	}
}
