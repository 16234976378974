import { useCallback, useEffect, useMemo, useRef } from 'react'
import { matchPath, useNavigate, useParams } from 'react-router-dom'
import {
	UseMutationOptions,
	UseQueryOptions,
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import { FormikHelpers } from 'formik'
import axios, { AxiosResponse } from 'axios'
import { getClientDetails, useMyProfile } from 'hooks/common'
import { APP_ROUTES } from 'const/router'
import { useUrlQuery } from 'hooks/url'
import { ENDPOINTS } from 'const/endpoints'
import { errorNotify, successNotify } from 'utils/toster'
import { scrollToError } from 'utils/common'
import { commonMessages } from 'utils/messages'
import { ConditionInitialState, ConditionPayload } from './MemberCondition.types'
import { IFullConditions } from './MemberCondition.interface'
import {
	createCondition,
	getCondition,
	getConditionsProvider,
	getMemberAssigneeDropdown,
	getMemberConditionsDropdown,
	updateCondition,
} from './MemberCondition.service'

/**
 * * Formik useInitial Section
 */
export function useInitial() {
	const alreadyMounted = useRef(false)
	const [condition] = useUrlQuery<any>()

	const id = condition?.condition
	const { data, isFetched } = useCondition({
		id,
		clientId: getClientDetails()?.clientId,
		suspense: !alreadyMounted.current, //TO USE SUSPENSE ONCE ON MOUNT
	})

	useEffect(() => {
		alreadyMounted.current = true
	}, [isFetched])

	return useMemo(() => {
		return new ConditionInitialState(id ? data?.data : {})
	}, [data?.data, id])
}

/**
 * * Formik useSubmit Section
 */

/**
 * * The useConditions  function is a React hook that takes in a set of query parameters.
 */
export function useConditions(id: string | number | any, clientId?: string | undefined) {
	// export function useConditions(id: string | number | any) {
	return useQuery<any>(
		[ENDPOINTS.PATIENT_PROGRAM_CONDITIONS + id, clientId],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getMemberConditionsDropdown(id, clientId, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	)
}
/**
 * * The useConditionsAssignee function is a React hook that gets partner assignee.
 */
export function useConditionsAssignee({
	id = '',
	limit,
	page,
	isEnabled = true,
	search = '',
}: Partial<{
	id: string
	limit: number
	page: number
	isEnabled: boolean
	search: string
}>) {
	return useQuery<any>(
		[ENDPOINTS.CONDITION_PARTNER_ASSIGNEE, id, limit, page, search],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getConditionsProvider(
				id,
				{
					limit,
					page,
					search,
				},
				source,
			)
		},
		{ enabled: isEnabled, keepPreviousData: true, refetchOnWindowFocus: false },
	)
}
// * * The useAssignee function is a React hook that gets Providers.

export function useAssignee({
	clientId,
	partner,
	page,
	search,
}: {
	clientId: string
	partner: string
	page: string
	search: string
}) {
	return useQuery<any>(
		[ENDPOINTS.PROVIDERS_DOCTORS, clientId, partner, page, search],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getMemberAssigneeDropdown(clientId, { partner, page, search }, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	)
}

interface IConditionQueryProps
	extends Partial<UseQueryOptions<AxiosResponse<IFullConditions>>>,
		Partial<IFullConditions> {
	patient?: string
	clientId?: string
}

export function useCondition({ id, clientId, ...rest }: IConditionQueryProps = {}) {
	return useQuery<AxiosResponse<IFullConditions>, any, AxiosResponse<IFullConditions>>(
		[ENDPOINTS.PATIENT_CONDITION, id, clientId],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getCondition(id, clientId, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: true,
			enabled: !!id,
			...rest,
		},
	)
}

interface IAddConditionProps
	extends Partial<
		UseMutationOptions<AxiosResponse<ConditionPayload>, any, Partial<ConditionPayload>>
	> {
	id?: string
	clientId?: string
}

export function useAddCondition({ id, clientId, ...rest }: IAddConditionProps = {}) {
	return useMutation<AxiosResponse<ConditionPayload>, any, Partial<ConditionPayload>>(
		async (data) => await createCondition(id, clientId, data),
		{
			...rest,
		},
	)
}

interface IUpdateConditionProps
	extends Partial<
		UseMutationOptions<AxiosResponse<ConditionPayload>, any, Partial<ConditionPayload>>
	> {
	id?: string
	conditionId: string
	clientId: string
}

export function useUpdateCondition({ id, conditionId, clientId, ...rest }: IUpdateConditionProps) {
	return useMutation<AxiosResponse<ConditionPayload>, any, Partial<ConditionPayload>>(
		async (data) => await updateCondition(id, conditionId, clientId, data),
		{
			...rest,
		},
	)
}

export function useSubmitHandler(setAddCondition: any, editCallBack: any, refetch: any) {
	const params = useParams<{ id: string }>()
	let id = params.id
	const client = useQueryClient()
	const [condition] = useUrlQuery<any>()
	const conditionId = condition?.condition
	const navigate = useNavigate()
	const profile = useMyProfile()
	if (matchPath(APP_ROUTES.ME, location.pathname)) {
		id = profile.id
	}
	const { mutateAsync: create } = useAddCondition({ id, clientId: getClientDetails()?.clientId })
	const { mutateAsync: update } = useUpdateCondition({
		id,
		conditionId,
		clientId: getClientDetails()?.clientId,
	})
	return useCallback(
		async (form: ConditionInitialState, formikHelpers: FormikHelpers<ConditionInitialState>) => {
			formikHelpers.setSubmitting(true)
			form.patient = id
			try {
				if (!conditionId) {
					const result = await create(new ConditionPayload(form))
					if (result.data.patient) {
						navigate(result.data.patient, {
							replace: true,
						})
					}
					successNotify(commonMessages.createMessage)
					client.invalidateQueries([ENDPOINTS.PATIENT_CONDITIONS], {
						type: 'all',
					})
				}
				if (conditionId) {
					await update(new ConditionPayload(form))
					successNotify(commonMessages.updateMessage)
					navigate(APP_ROUTES.MEMBER_PROFILE + `/${id}/conditions`)
					client.invalidateQueries([ENDPOINTS.PATIENT_CONDITIONS], {
						type: 'all',
					})
				}
				client.invalidateQueries([ENDPOINTS.PATIENTS])

				setAddCondition(false)
			} catch (err: any) {
				formikHelpers.setErrors(err.response.data) //TODO - ADD ERRORS TRANSFORMATION TO FORM FORMAT
				scrollToError()
				if (err?.response?.data?.message) {
					errorNotify(err.response.data.message)
				} else {
					errorNotify(commonMessages.errorHandlingMessage)
				}
			}
		},
		[create, id, navigate, update, editCallBack, conditionId],
	)
}
