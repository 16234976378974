import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, useFormikContext } from 'formik'
import { getClientDetails } from 'hooks/common'
import { DROPDOWN_LIMIT } from 'const/common'
import { useUrlQuery } from 'hooks/url'
import Button from 'components/Button'
import FormikInputField from 'components/FormikInputField'
import FormikRadioButtonField from 'components/FormikRadioButtonField'
import FormikAdvancedSelectDropdown from 'components/AdvancedScrollSelectField/FormikAdvancedSelectDropdown'
import { FIELD_NAMES, IS_ACTIVE_OPTIONS } from '../Roles.constants'
import { IRoleBaseParams } from './Role.interface'
import { RoleBaseInitialState } from './Role.types'
import { useGetRole } from '../Roles.hooks'
import CheckboxField from 'components/CheckboxField'

export function RoleComponent() {
	/**
	 * Context Section
	 */

	const context = useFormikContext<RoleBaseInitialState>()
	const [urlParams] = useUrlQuery<IRoleBaseParams>()

	/**
	 * * State Section
	 */
	const [searchRole, setSearchRole] = useState('')
	const [parentRole, setParentRole] = useState<any>([])
	const [parentRolePageProp, setParentRolePageProp] = useState(1)
	/**
	 * *Custom hooks Section
	 */

	const params = useParams()
	const navigate = useNavigate()
	const isRole: any = useGetRole({
		...urlParams,
		client: getClientDetails()?.clientId,
		id: urlParams?.id,
		search: searchRole,
		page: parentRolePageProp,
		limit: DROPDOWN_LIMIT.LIMIT,
	})

	/**
	 * Functions Section
	 */

	const handleValues = (
		event: React.ChangeEvent<HTMLInputElement>,
		fieldName: string | undefined = '',
	) => {
		const checked = event?.target?.checked
		context.setFieldValue(fieldName, checked)
	}

	/**
	 * *useEffects Section
	 * @param useEffectName
	 */
	useEffect(() => {
		if (isRole?.data?.data?.items?.length) {
			const existingValues = parentRole?.map((option: any) => option.value)
			const items = isRole?.data?.data?.items
				?.filter((item: any) => !existingValues.includes(item.id))
				?.map((item: any) => ({
					...item,
					label: item?.roleName,
					value: item?.id,
				}))
			setParentRole([...parentRole, ...items])
		}
	}, [isRole?.data?.data?.items])

	const isRoleDataFetching = isRole?.data?.isFetching ?? false

	/**
	 * *JSX Section
	 * @returns Role Form
	 */

	return (
		<div className="px-4 md:px-auto flex flex-col">
			<Form>
				<>
					<div className=" grid md:grid-cols-2 gap-x-4 gap-y-2">
						<FormikInputField
							name={FIELD_NAMES.ROLE_NAME}
							label="Role Name"
							placeholder="Role Name"
							require="true"
						/>

						<FormikAdvancedSelectDropdown
							isClearable
							isSearchable
							label="Parent Role"
							placeholder="Select Parent Role"
							className="capitalize"
							isFetching={isRoleDataFetching}
							options={parentRole}
							name={FIELD_NAMES.PARENT_ROLE}
							page={parentRolePageProp}
							totalPages={isRole?.data?.data?.meta?.totalPages || 0}
							setPage={setParentRolePageProp}
							search={searchRole}
							setSearch={setSearchRole}
						/>

						<FormikRadioButtonField
							name={FIELD_NAMES.IS_ACTIVE}
							options={IS_ACTIVE_OPTIONS}
							className="pb-2"
							classNames={{
								inlineLabel: 'text-[#98A0A6] text-[16px] !font-normal',
								message: 'text-red-400',
								inputWrapper: 'ml-4 whitespace-nowrap',
								input: 'pl-0',
							}}
						/>
						<div className="flex items-center">
							<CheckboxField
								name={FIELD_NAMES.IS_CLIENT}
								isChecked={context?.values?.[FIELD_NAMES.IS_CLIENT]}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									handleValues(event, FIELD_NAMES.IS_CLIENT)
								}
								classNames={{ children: 'text-sm', content: 'mt-4' }}
							>
								Client
							</CheckboxField>
							<CheckboxField
								name={FIELD_NAMES.IS_PARTNER}
								isChecked={context?.values?.[FIELD_NAMES.IS_PARTNER]}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									handleValues(event, FIELD_NAMES.IS_PARTNER)
								}
								classNames={{ children: 'text-sm', content: 'mt-4 ml-4' }}
							>
								Partner
							</CheckboxField>
						</div>
					</div>
				</>
				<div className="flex gap-4 mt-4 justify-end flex-col md:flex-row">
					<Button
						className="mt-2"
						buttonType="button"
						type="default"
						disabled={context.isSubmitting}
						onClick={() => navigate(-1)}
					>
						Cancel
					</Button>
					<Button
						className="mt-2"
						buttonType="submit"
						type="primary"
						disabled={context.isSubmitting}
					>
						{urlParams?.id || params?.id ? 'Update' : 'Create'}
					</Button>
				</div>
			</Form>
		</div>
	)
}
