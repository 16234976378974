import { IClient } from 'modules/Clients/Clients.interface'
import { SelectOption } from 'types/entities'
import { ISelectOption } from 'types/forms'
import { convertUnixWithEndOf, formatDate } from 'utils/time'

export class ReferralPayload {
	conditions: any
	patient: any
	referTo: any
	validUpTo: any
	chiefComplaints: any
	document: any
	specialties: any
	toDelete: any
	client: string | null = null
	partner: string | null = null

	constructor({ ...data }: Partial<any> = {}) {
		this.addCondition(data?.conditions)
		this.addPatient(data?.patient)
		this.addProvider(data?.referTo)
		this.addValidUpto(data?.validUpTo)
		this.addCheifComplaints(data?.chiefComplaints)
		this.addDocuemnt(data?.document)
		this.addSpeciality(data?.specialties)
		this.addToDelete(data?.toDelete)
		this.addClient(data?.client)
		this.addPartner(data?.partner)
	}
	addClient(client: ISelectOption | undefined | null) {
		if (client) {
			this.client = client?.value
		}
	}
	addPartner(partner: ISelectOption | undefined | null) {
		if (partner) {
			this.partner = partner?.value
		}
	}
	addSpeciality(data: any) {
		this.specialties = [data?.value]
	}

	addCondition(data: any) {
		this.conditions = [data?.value]
	}
	addPatient(data: any) {
		this.patient = data?.value
	}
	addProvider(data: any) {
		this.referTo = data?.value
	}
	addValidUpto(data: any) {
		this.validUpTo = convertUnixWithEndOf(data)
	}
	addCheifComplaints(data: any) {
		this.chiefComplaints = data
	}
	addDocuemnt(data: any = []) {
		this.document = data
	}
	addToDelete(data: any) {
		if (data?.length) {
			this.toDelete = data
		} else {
			delete this.toDelete
		}
	}
}

export class ReferralInitalState {
	conditions: string | null = null
	patient: any
	referTo: any
	validUpTo: any
	chiefComplaints: any
	uploadDocuments: any
	document: any
	specialties: any
	toDelete: any
	client: ISelectOption | null = null
	partner: ISelectOption | null = null

	constructor({ ...data }: any) {
		this.addCondition(data?.conditions)
		this.addPatient(data?.patient)
		this.addProvider(data?.referTo)
		this.addValidUpto(data?.validUpTo)
		this.addCheifComplaints(data?.chiefComplaints)
		this.toDelete = []
		this.addDocuemnt(data?.document)
		this.addSpeciality(data?.specialties)
		this.addClient(data?.client)
		this.addPartner(data?.partner)
	}
	addClient(client: IClient | undefined) {
		if (client) {
			this.client = new SelectOption(client?.id, client?.title)
		}
	}
	addPartner(partner: string | undefined | any) {
		if (partner) {
			this.partner = new SelectOption(partner?.id, partner?.companyName)
		}
	}
	addSpeciality(data: any) {
		if (data) {
			const options = data?.map((item: any) => {
				return {
					label: item?.speciality.speciality,
					value: item?.speciality?.id,
				}
			})
			this.specialties = options?.[0]
		} else {
			this.specialties = ''
		}
	}

	addCondition(data: any) {
		if (data) {
			const options = data?.map((item: any) => {
				return {
					label: item?.condition?.condition,
					value: item?.condition?.id,
				}
			})
			this.conditions = options?.[0]
		} else {
			this.conditions = ''
		}
	}
	addPatient(data: any) {
		if (data) {
			this.patient = {
				label: data?.humanName.text,
				value: data?.id,
			}
		} else {
			this.patient = ''
		}
	}
	addValidUpto(data: string) {
		if (data) {
			this.validUpTo = formatDate(Number(data))
		} else {
			this.validUpTo = ''
		}
	}
	addCheifComplaints(chiefComplaints: string) {
		if (chiefComplaints) {
			this.chiefComplaints = chiefComplaints
		} else {
			this.chiefComplaints = ''
		}
	}
	addDocuemnt(document: any) {
		if (document) {
			const data = document?.map((doc: any) => {
				return {
					...doc,
					isEdit: true,
				}
			})
			this.document = data
		} else {
			this.document = ''
		}
	}

	addProvider(data: any) {
		if (data) {
			this.referTo = {
				label: data?.humanName.text,
				value: data?.id,
			}
		} else {
			this.referTo = ''
		}
	}
}
