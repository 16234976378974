import TableSortHeader from 'components/TableSortHeader'

export enum FIELD_NAMES {
	ROLE_NAME = 'roleName',
	CLIENT = 'client',
	ROLETYPE = 'roleType',
	DESCRIPTION = 'description',
	IS_ACTIVE = 'isActive',
	PARENT_ROLE = 'parentRole',
	PROVIDER_SERVICES = 'PROVIDER_SERVICES',
	IS_CLIENT = 'isClient',
	IS_PARTNER = 'isPartner',
}

export enum RoleLabels {
	CLIENT_NAME = 'Client Name',
	DISABLED_ADMIN = 'f4cc90bf-7ca4-4571-9fb6-73e23196c17a',
}

export const IS_ACTIVE_OPTIONS = [
	{ value: 'isActive', label: 'Is Active' },
	{ value: 'isInactive', label: 'Is Inactive' },
]

export const RoleBaseColumns = [
	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}> Role Name</TableSortHeader>,
		accessor: 'col1',
		name: 'roleName',
		sortable: true,
	},
	// {
	// 	Header: ({ ...rest }) => <TableSortHeader {...rest}>Role Type</TableSortHeader>,
	// 	accessor: 'col2',
	// 	name: 'roleType',
	// 	sortable: true,
	// },

	{
		Header: ({ ...rest }) => <TableSortHeader {...rest}>Client Name</TableSortHeader>,
		accessor: 'col3',
		name: 'client',
		sortable: true,
	},

	{
		Header: 'Created On',
		accessor: 'col4',
		name: 'createdOn',
		sortable: false,
	},
	// {
	// 	Header: ({ ...rest }) => <TableSortHeader {...rest}>isActive</TableSortHeader>,
	// 	accessor: 'col5',
	// 	name: 'isActive',
	// 	sortable: true,
	// },
	{
		Header: 'Default Routes',
		accessor: 'col6',
		name: 'defaultRoutes',
		sortable: true,
	},
	{
		Header: 'Action',
		accessor: 'col7',
		name: 'actions',
		sortable: true,
	},
]

export const specialCharacters = [
	'!',
	'@',
	'#',
	'$',
	'%',
	'^',
	'&',
	'*',
	'(',
	')',
	'-',
	'_',
	'+',
	'=',
	'{',
	'}',
	'[',
	']',
	'|',
	'\\',
	';',
	':',
	'"',
	"'",
	'<',
	'>',
	',',
	'.',
	'?',
	'/',
]
// f4cc90bf-7ca4-4571-9fb6-73e23196c17a
