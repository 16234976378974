import { ISelectOption } from 'types/forms'
import {
	SESSION_FREQUENCY,
	SESSION_FREQUENCY_OPTIONS,
	SESSION_PERIOD,
	SESSION_TYPE,
	SessionDurationInitialValue,
	TO_BE_COMPLETED,
} from './Session.constants'
import { IFullSession, ISessionDuration } from '../Session.interfaces'

export class SessionPayload {
	program?: string | undefined
	existingId?: string | undefined
	type: SESSION_TYPE = SESSION_TYPE.SINGLE_SESSION
	typeId: string | undefined
	title: string | undefined | null = null
	duration: ISessionDuration = SessionDurationInitialValue
	toBeCompleted: TO_BE_COMPLETED | null = null
	toBeCompletedId: ISelectOption | null | any = null
	frequency?: SESSION_FREQUENCY | null = null
	period?: SESSION_PERIOD | null = null
	periodId?: ISelectOption | null = null
	description?: string | undefined | null = null
	document?: (File | string | any) | null = null
	previewImage?: string | null
	sessionCode: string | undefined
	questionnaireAnsweredBy: string | null | undefined = null
	questionnaireAnsweredById: ISelectOption | null = null
	totalSession: number | undefined | null = null
	constructor({
		program,
		existingId,
		type,
		typeId,
		title,
		duration,
		toBeCompleted,
		toBeCompletedId,
		frequency,
		period,
		periodId,
		description,
		document,
		questionnaireAnsweredBy = null,
		questionnaireAnsweredById,
		totalSession,
		sessionCode,
		...data
	}: Partial<SessionInitialState> = {}) {
		this.addProgram(program)
		this.addType(type)
		this.addTypeId(typeId)
		this.addExistingId(existingId)
		this.addTitle(title)
		this.addDuration(duration)
		this.addToBeCompleted(toBeCompletedId)
		this.addFrequency(frequency)
		this.addPeriod(periodId)
		this.addDescription(description)
		this.addAnsweredBy(questionnaireAnsweredById)
		this.addAssets(document)
		this.addSessionCode(sessionCode)
		this.addTotalSession(totalSession)
	}
	addSessionCode(sessionCode: string | undefined) {
		this.sessionCode = sessionCode
	}

	addAnsweredBy(anseredBy: ISelectOption | null | any = null) {
		this.questionnaireAnsweredBy = anseredBy?.value
		this.questionnaireAnsweredById = anseredBy?.id
	}
	addProgram(program: string | undefined) {
		this.program = program
	}
	addExistingId(existingId: string | undefined) {
		this.existingId = existingId
	}

	addType(type?: any | any) {
		if (type) {
			this.type = type
		}
	}
	addTypeId(type?: any | any) {
		if (type) {
			this.typeId = type
		}
	}

	addTitle(title: string | undefined | null) {
		this.title = title
	}

	addTotalSession(total: number | null | undefined) {
		this.totalSession = total
	}
	addDuration(duration?: ISessionDuration) {
		if (duration) {
			const sessionDuration = {
				hours: '',
				minutes: '',
			}
			if (duration.hours) {
				sessionDuration.hours = duration.hours.value
			}
			if (duration.minutes) {
				sessionDuration.minutes = duration.minutes.value
			}
			this.duration = sessionDuration
		}
	}

	addToBeCompleted(toBeCompleted: ISelectOption | null | any = null) {
		if (toBeCompleted) {
			this.toBeCompleted = toBeCompleted?.value
			this.toBeCompletedId = toBeCompleted?.id
		}
	}

	addFrequency(frequency: ISelectOption<SESSION_FREQUENCY> | null = null) {
		if (frequency) {
			this.frequency = frequency.value
		} else {
			delete this.frequency
		}
	}

	addPeriod(period: ISelectOption | null | any = null) {
		if (period) {
			this.period = period?.label
			this.periodId = period?.id
		} else {
			delete this.period
			delete this.periodId
		}
	}

	addDescription(description: string | undefined | null = null) {
		if (description) {
			this.description = description
		} else {
			delete this.description
		}
	}

	addAssets(assets: any) {
		if (assets) {
			this.document = assets
		} else {
			this.document = ''
		}
	}
}

export class SessionInitialState {
	program?: string | undefined
	existingId?: string | undefined
	type: SESSION_TYPE = SESSION_TYPE.SINGLE_SESSION
	typeId: string | undefined | null = null
	title: string | undefined
	totalSession?: number | null | undefined = null
	duration: ISessionDuration = SessionDurationInitialValue
	toBeCompleted: ISelectOption<TO_BE_COMPLETED> | null = null
	toBeCompletedId: ISelectOption | null | any = null
	frequency: ISelectOption<SESSION_FREQUENCY> | null = null
	period: ISelectOption<SESSION_PERIOD> | null = null
	periodId: ISelectOption | null | any = null
	serverImage?: string | null = null
	description: string | null = null
	sessionCode: string | undefined
	isFetched?: boolean | null | undefined = true
	questionnaireAnsweredBy:
		| {
				label: string
				value: string
		  }
		| null
		| undefined = null
	questionnaireAnsweredById: ISelectOption | null | any = null

	document?: (File | string | any) | null = null
	previewImage?: string | null
	constructor({
		program,
		type,
		typeId,
		existingId,
		title,
		duration,
		toBeCompleted,
		toBeCompletedId,
		frequency,
		period,
		periodId,
		description,
		document,
		questionnaireAnsweredBy = null,
		questionnaireAnsweredById,
		totalSession,
		sessionCode,
		isFetched,
		...data
	}: Partial<IFullSession> = {}) {
		this.addProgram(program)
		this.addType(type)
		this.addTypeId(typeId)
		this.addExistingId(existingId)
		this.addTitle(title)
		this.addDuration(duration)
		this.addToBeCompleted(toBeCompletedId)
		this.addFrequency(frequency)
		this.addPeriod(periodId)
		this.addDescription(description)
		this.addAssets(document)
		this.addAnsweredBy(questionnaireAnsweredById)
		this.addTotalSession(totalSession)
		this.addSessionCode(sessionCode)
		this.addIsFetched(isFetched)
	}
	addIsFetched(isFetched: boolean | null | undefined) {
		this.isFetched = isFetched
		// delete this.isFetched
	}
	addSessionCode(sessionCode: string | undefined) {
		this.sessionCode = sessionCode
	}
	addAnsweredBy(data: ISelectOption | any) {
		if (data) {
			this.questionnaireAnsweredById = {
				label: data?.globalCodesName,
				value: data?.slug,
				id: data?.id,
			}
		}
	}
	addProgram(program: string | undefined) {
		this.program = program
	}
	addTotalSession(total: number | null | undefined) {
		this.totalSession = total
	}
	addType(type?: any) {
		if (type) {
			this.type = type?.value
			this.typeId = type?.id
		}
	}
	addTypeId(type?: any) {
		if (type) {
			this.type = type?.value
			this.typeId = type?.id
		}
	}
	addExistingId(existingId: string | undefined) {
		this.existingId = existingId
	}

	addTitle(title: any) {
		this.title = title
	}

	addDuration(duration?: ISessionDuration) {
		if (duration) {
			this.duration = {
				hours: { value: duration?.hours, label: duration?.hours },
				minutes: { value: duration?.minutes, label: duration?.minutes },
			}
		}
	}

	addToBeCompleted(toBeCompleted?: ISelectOption | any) {
		if (toBeCompleted) {
			this.toBeCompletedId = {
				label: toBeCompleted?.globalCodesName,
				value: toBeCompleted?.slug,
				id: toBeCompleted?.id,
			}
		}
	}

	addFrequency(frequency?: SESSION_FREQUENCY) {
		if (frequency) {
			const foundValue = SESSION_FREQUENCY_OPTIONS.find((i) => i.value === frequency)
			if (foundValue) {
				this.frequency = foundValue
			}
		}
	}

	addPeriod(period?: ISelectOption | any) {
		if (period) {
			this.periodId = {
				label: period?.globalCodesName,
				value: period?.slug,
				id: period?.id,
			}
		}
	}

	addDescription(description: string | undefined) {
		if (description) {
			this.description = description
		}
	}

	addAssets(assets: any) {
		if (assets) {
			this.serverImage = assets
			this.document = assets
		}
	}
}

export class SessionInitialStateWithAdProp extends SessionInitialState {
	isFetched?: boolean | undefined = false
}
