import _ from 'lodash'
import { IVitalAlertsParams } from './MemberCareTeam.interface'
import { IPagination } from 'types/entities'
import { commonMessages } from 'utils/messages'
import { ENDPOINTS } from 'const/endpoints'
import axios from 'services/axios'
import { CancelTokenSource } from 'axios'
import { CareTeamPayload } from './MemberCareTeam.types'
/**
 * * getMemberVitalAlerts
 */
export function getMemberVitalAlerts(
	id: string | undefined,
	source?: CancelTokenSource,
	params?: Partial<IVitalAlertsParams>,
) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get<IPagination<Partial<any>>>(ENDPOINTS.VITAL_ALERTS + '/' + id, {
		params: filteredParams,
	})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function getCareTeamAdd(
	params: {
		id: string | undefined
	},
	clientId?: string | undefined,
	source?: CancelTokenSource,
) {
	const promise = axios.get<IPagination<Partial<any>>>(
		ENDPOINTS.PROVIDERS_CARE_TEAM + '/' + clientId + '/' + params?.id,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function createCareTeam(
	clientId?: string | undefined,
	data?: Partial<CareTeamPayload>,
	source?: CancelTokenSource,
) {
	const promise = axios.post<CareTeamPayload>(ENDPOINTS.PATIENTS_CARE_TEAm + '/' + clientId, data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
