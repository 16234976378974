import { Auth } from 'firebase/auth'
import { IFullProvider } from 'modules/Providers/Provider.interfaces'
import { RouteObject } from 'react-router-dom'
import { IPermissionDetails } from './permission.types'

type RolesType = 'public' | 'private' | 'common'

export enum ROLES {
	ADMIN = 'admin',
	MA = 'ma',
	DOCTOR = 'doctor',
	SUPER_ADMIN = 'superAdmin',
	PROGRAM_MANAGER = 'programManager',
	PARTNER = 'partner',
	CARE_COORDINATOR = 'careCoordinator',
	PROGRAM_COORDINATOR = 'programCoordinator',
	EPIDEMIOLOGIST = 'epidemiologist',
	PROVIDER = 'provider',
	CHCT = 'chct',
	COMMUNITY_BASED_PARTNER = 'CommunityBasedPartner',
}

export enum PARTNER_ROLES {
	CLINIC_SITE = 'clinic site',
	HEALTH_COACH = 'healthcoach',
	TRAINER = 'trainer',
}

export const USER_ROLES_BY_ACCESS_LEVEL = {
	// values are access level where 0 is simple user and 3 super admin
	// [ROLES.MA]: 0,
	// [ROLES.DOCTOR]: 1,
	// [ROLES.ADMIN]: 2,
	// [ROLES.SUPER_ADMIN]: 3,
	// [ROLES.PARTNER]: 2,
	// [ROLES.CARE_COORDINATOR]: 0.9,
	// [ROLES.PROGRAM_MANAGER]: 0.6,
	// [ROLES.PROGRAM_COORDINATOR]: 0.5,
	[ROLES.MA]: 1,
	[ROLES.DOCTOR]: 2,
	[ROLES.ADMIN]: 3,
	[ROLES.SUPER_ADMIN]: 4,
	[ROLES.PARTNER]: 5,
	[ROLES.CARE_COORDINATOR]: 2,
	[ROLES.PROGRAM_MANAGER]: 3,
	[ROLES.PROGRAM_COORDINATOR]: -1,
	[ROLES.EPIDEMIOLOGIST]: 1,
	[ROLES.COMMUNITY_BASED_PARTNER]: 1,
}

export enum PARTNER_STATUS {
	ACTIVE = 'active',
	IN_ACTIVE = 'inactive',
}

export enum PARTNER_TYPE {
	HEALTH_CARE = 'healthCare',
	NON_HEALTH_CARE = 'nonHealthCare',
}

export enum PARTNER_ACCESS {
	APPROVED = 'access_provider',
	DENIED = 'access_denied',
}

export enum PARTNER_ROLE {
	MEDICAL_ASSISTANT = 'ma',
	DOCTOR = 'doctor',
	PROVIDER = 'provider',
	CARE_COORDINATOR = 'care_coordinator',
	PROGRAM_COORDINATOR = 'program_coordinator',
}

export enum TIME_RANGE {
	DAY = 'day',
	WEEK = 'week',
	DATE_RANGE = 'dateRange',
}

export enum WEEK_RANGE {
	Month1 = '1',
	Month3 = '3',
	Month6 = '6',
	Month9 = '9',
	Month12 = '12',
}
export enum VITAL_TYPE_PROGRAM {
	BLOOD_PRESSURE = 'bloodPressure',
	GLUCOSE = 'glucose',
	WEIGHT = 'weight',
	TEMPERATURE = 'temperature',
	OXYGEN_SATURATION = 'oxygenSaturation',
}

export enum CONTACT_POINT_TYPE {
	PHONE = 'phone',
	EMAIL = 'email',
	FAX = 'fax',
}

export enum SESSION_TYPE {
	SINGLE_SESSION = 'singleSession',
	MULTIPLE_SESSION = 'multipleSession',
}

export enum VITAL_TYPE {
	BLOOD_PRESSURE = 'bloodPressure',
	GLUCOSE = 'glucose',
	WEIGHT = 'weight',
	TEMPERATURE = 'temperature',
	OXYGEN_SATURATION = 'oxygenSaturation',
	empty = '',
}

export enum CHECK_IN_INTERVAL {
	DAY = 'daily',
	WEEK = 'weekly',
	MONTH = 'monthly',
}

export enum TRACKING_DEVICE {
	GLUCOMETER = 'glucometer',
	BLOOD_PRESSURE = 'bloodPressure',
	WEIGHT_MACHINE = 'weightMachine',
}
export enum ALERT {
	YES = 'yes',
	NO = 'no',
}
export interface IOutletAuth extends Auth {
	setAccessType: (type: RolesType) => void
	isAuth: boolean
	roles: ROLES[]
	profile: IFullProvider
	user: Auth['currentUser']
	serviceName: string
	permission: any
	servicePermission: IPermissionDetails
}
// @ts-ignore
export interface ICustomRouteObject extends RouteObject {
	private?: boolean
	public?: boolean
	children?: any
	// caseSensitive?: boolean
	// children?: RouteObject[]
	element?: React.ReactNode
	// index?: boolean
	// path?: string
}
export enum POC_ACCESS {
	ACCESS_PROVIDER = 'access_provider',
	ACCESS_DENIED = 'access_denied',
}

export interface IResetPasswordEmail {
	email: string | undefined
}

export interface IResetPasswordData {
	newPassword: string
	oobCode: string
}
