import { CONTACT_POINT, DEVICE_TYPE, MEASUREMENT_TYPE, VITAL_TYPE } from 'types/entities'

import { ReactComponent as BloodPressuareIcon } from 'assets/images/blood-pressure-gauge.svg'
import { ReactComponent as OximeterIcon } from 'assets/images/pulse-oximeter.svg'
import { ReactComponent as Scale } from 'assets/images/ic-scale.svg'
import { ReactComponent as GlucoseIcon } from 'assets/images/glucose.svg'
import { ReactComponent as TermometrIcon } from 'assets/images/ic-temperature.svg'

export const DEVICE_NAMES_BY_TYPE = {
	[DEVICE_TYPE.DEVICE_BP]: 'Blood pressure',
	[DEVICE_TYPE.DEVICE_BP_CELLULAR]: 'Blood pressure',
	[DEVICE_TYPE.DEVICE_GL]: 'Glucose',
	[DEVICE_TYPE.DEVICE_GL_CELLULAR]: 'Glucose',
	[DEVICE_TYPE.DEVICE_PO]: 'Oximeter',
	[DEVICE_TYPE.DEVICE_PO_CELLULAR]: 'Oximeter',
	[DEVICE_TYPE.DEVICE_SC]: 'Scale',
	[DEVICE_TYPE.DEVICE_SC_CELLULAR]: 'Scale',
	[DEVICE_TYPE.DEVICE_T]: 'Temperature',
	[DEVICE_TYPE.DEVICE_T_CELLULAR]: 'Temperature',
}

export const VITAL_TYPE_BY_DEVICE_TYPE = {
	[DEVICE_TYPE.DEVICE_BP]: VITAL_TYPE.BLOOD_PRESSURE,
	[DEVICE_TYPE.DEVICE_BP_CELLULAR]: VITAL_TYPE.BLOOD_PRESSURE,
	[DEVICE_TYPE.DEVICE_GL]: VITAL_TYPE.GLUCOSE,
	[DEVICE_TYPE.DEVICE_GL_CELLULAR]: VITAL_TYPE.GLUCOSE,
	[DEVICE_TYPE.DEVICE_PO]: VITAL_TYPE.OXYGEN_SATURATION,
	[DEVICE_TYPE.DEVICE_PO_CELLULAR]: VITAL_TYPE.OXYGEN_SATURATION,
	[DEVICE_TYPE.DEVICE_SC]: VITAL_TYPE.WEIGHT,
	[DEVICE_TYPE.DEVICE_SC_CELLULAR]: VITAL_TYPE.WEIGHT,
	[DEVICE_TYPE.DEVICE_T]: VITAL_TYPE.TEMPERATURE,
	[DEVICE_TYPE.DEVICE_T_CELLULAR]: VITAL_TYPE.TEMPERATURE,
}

export const DEVICE_TYPE_IMAGES = {
	[DEVICE_TYPE.DEVICE_BP]: <BloodPressuareIcon className="w-[16px] " />,
	[DEVICE_TYPE.DEVICE_BP_CELLULAR]: <BloodPressuareIcon className="w-[20px] " />,
	[DEVICE_TYPE.DEVICE_GL]: <GlucoseIcon className="w-[20px] " />,
	[DEVICE_TYPE.DEVICE_GL_CELLULAR]: <GlucoseIcon className="w-[20px] " />,
	[DEVICE_TYPE.DEVICE_PO]: <OximeterIcon className="w-[20px] " />,
	[DEVICE_TYPE.DEVICE_PO_CELLULAR]: <OximeterIcon className="w-[20px] " />,
	[DEVICE_TYPE.DEVICE_SC]: <Scale className="w-[16px]" />,
	[DEVICE_TYPE.DEVICE_SC_CELLULAR]: <Scale className="w-[20px] " />,
	[DEVICE_TYPE.DEVICE_T]: <TermometrIcon className="w-[20px] " />,
	[DEVICE_TYPE.DEVICE_T_CELLULAR]: <TermometrIcon className="w-[20px]" />,
}

export const VITAL_TYPE_IMAGES = {
	[VITAL_TYPE.BLOOD_PRESSURE]: <BloodPressuareIcon className="w-[16px] " />,
	[VITAL_TYPE.GLUCOSE]: <GlucoseIcon className="w-[20px] " />,
	[VITAL_TYPE.OXYGEN_SATURATION]: <OximeterIcon className="w-[20px] " />,
	[VITAL_TYPE.WEIGHT]: <Scale className="w-[16px]" />,
	[VITAL_TYPE.TEMPERATURE]: <TermometrIcon className="w-[20px] " />,
}

export const MEASUREMENT_TYPE_TITLES = {
	[MEASUREMENT_TYPE.BODY_SCALE]: 'Weight, lb:',
	[MEASUREMENT_TYPE.BLOOD_GLUCOSE_BG]: 'Level:',
	[MEASUREMENT_TYPE.BLOOD_PRESSURE_DIA]: 'Diastolic:',
	[MEASUREMENT_TYPE.BLOOD_PRESSURE_SYS]: 'Systolic:',
	[MEASUREMENT_TYPE.BLOOD_PRESSURE_HB]: 'Pulse:',
	[MEASUREMENT_TYPE.THERMOMETER_C]: 'Temp, F:',
	[MEASUREMENT_TYPE.PULSE_OXIMETER_O]: 'Pressure, bpm:',
}

export const VITAL_TYPE_TITLES = {
	[VITAL_TYPE.BLOOD_PRESSURE]: 'Blood Pressure',
	[VITAL_TYPE.GLUCOSE]: 'Glucose',
	[VITAL_TYPE.OXYGEN_SATURATION]: 'Pulse Oximeter',
	[VITAL_TYPE.WEIGHT]: 'Scale',
	[VITAL_TYPE.TEMPERATURE]: 'Temperature',
}

export const IS_ACTIVE_OPTIONS = [
	{ value: 'isActive', label: 'Active' },
	{ value: 'isInactive', label: 'Inactive' },
]
export const IS_WISE_WOMAN_OPTIONS = [
	{ value: true, label: 'Is wisewoman' },
	{ value: false, label: 'Is not wisewoman' },
]

export const CONTACT_POINT_TYPES_OPTIONS: {
	label: string
	value: CONTACT_POINT
}[] = [
	{ label: 'Phone', value: CONTACT_POINT.PHONE },
	{ label: 'Email', value: CONTACT_POINT.EMAIL },
	{ label: 'Fax', value: CONTACT_POINT.FAX },
]

export enum FIELD_NAMES {
	POSTAL_CODE = 'postalCode',
	CITY = 'city',
	STATE = 'state',
	ACTIVE = 'active',
}

export enum MEMBER_CURRENT_URL {
	MEMBER_PROFILE = 'member-profile',
	SESSION_VIEW = 'session-view',
}

export enum AUTH_ERRORS {
	UNAUTHORIZED = 'Unauthorized',
	PERMISSION_DENIED = 'Permission denied',
}

// SCREEN WRAPPER CONTENT AND TITLE CLASSNAME
export enum SCREEN_WRAPPER_CONTENT {
	CONTENT_CLASSNAME = 'bg-fountainBlue',
	CONTENT_TITLE = 'border-b-fountainBlue',
}

// Common Classess used
export enum COMMON_FIELD_CLASSNAME {
	THREE_WIDTH_COLUMN = 'w-1/3 p-2',
	FULL_WIDTH_COLUMN = 'w-full p-2',
	MARGIN_TOP_TWO = 'mt-2',
	FIELD_WRAAPER = 'w-1/2 p-2',
	FORM_TABLE_SCROLL = 'content-height scrollbar-thin scrollbar-thumb-fountainBlue scrollbar-track-gray-200 overflow-y-auto pr-4 sm:pr-8 sm:pl-5 pl-2',
}
// Sections used
export enum SECTION_NAME {
	FILTER = 'filter',
	GLOBAL_FILTER = 'globalfilter',
	GRID = 'grid',
	GLOBAL_GRID = 'globalgrid',
	// Provider
	ADD_PROVIDER = 'addprovider',
	PROFILE = 'profile',
	// Partner
	ADD_PARTNER = 'addpartner',
	PARTNER_PROFILE = 'partnerprofile',
	// Program
	ADD_PROGRAM = 'addprogram',
	PROGRAM_PROFILE = 'programprofile',
	PROGRAM_CRITERIA = 'programCriteria',
	GLOBAL_PROGRAM_CRITERIA = 'globalProgramCriteria',
	PROGRAM_APPLICABLE_FOR = 'programApplicableFor',
	GLOBAL_PROGRAM_APPLICABLE_FOR = 'globalProgramApplicableFor',
	ADD_REFERRAL = 'addreferrals',
	ADD_FEE_SCHEDULE = 'addfeeschedule',
	// Members
	ADD_MEMBER = 'addmember',
	GENERAL_INFORMATION = 'generalInformation',
	INSURANCE_INFO = 'insuranceInfo',
	PROVIDER_INFORMATION = 'providerInformation',
	ADD_LOCATION = 'addlocation',
	ADD_SCHEDULE = 'addschedule',
	ADD_POINT_OF_CONTACT = 'addpointofcontact',
	ADD_VITAL = 'addvital',
	GLOBAL_ADD_VITAL = 'globaladdvital',
	ADD_SESSION = 'addsession',
	Global_ADD_SESSION = 'globaladdsession',
	ADD_GOALS = 'addgoals',
	GLOBAL_ADD_GOALS = 'globaladdgoals',
	VIEW_CPT = 'viewcpt',
	EDIT_QUESTIONNARIE = 'editquestionnaire',
	ADD_JOURNAL = 'addjournal',
	ADD_CONDITION = 'addcondition',
	ADD_DEVICE = 'adddevice',
	ASSIGN_PROGRAM = 'assignprogram',
	VITAL_THRESHOLDS = 'vitalthresholds',
	CARE_TEAM = 'careteam',
	BOOKAPPTMENT = 'bookappointmentfor',
	ASSIGNPROGRAM = 'assignprogram',
	MEMBER_REFERRAL = 'referral',
	MEMBER_CARETEAM = 'careteam',
	ADD_GROUP = 'Add Group',
	CREATE_NEW_GROUP = 'createnewgroup',
	CREATE_TEMPLATE = 'createtemplate',
	ASSIGN_TEMPLATE = 'assigntemplate',
	ADD_GLOBAL_PROGRAM = 'addglobalprogram',
	GLOBAL_ADD_PROGRAM = 'Add Global Program',
	ADD_GLOBLE_SESSIONS = 'Add Session',
	ADD_SCREENING = 'Create Screening',
	ADD_QUESTION = 'addquestion',
	ASSIGN_GLOBAL_PROGRAM = 'assignglobalprogram',
	ADD_GLOBAL_SESSION = 'addglobalsession',
	ASSIGN_GLOBAL_SESSION = 'assignglobalsession',
	ADD_SESSIONS = 'addsession',
	CHAT_SELECT = 'select',
	ASSIGNS_SCREENING = 'assignscreening',
	SCREENING_INFORMATION = 'screeninginformation',
	SCREENING_TILES = 'screeningtiles',
	SCORING_NON_SCORING = 'scoringnonscoring',
	MEMBER_LIST = 'memberlist',
	SESSION = 'session',
	ADDQUESTION = 'addquestion',
	SCREENING_LIBRARY = 'screening-library',
	// epi  dashboard
	LEAST_PERFORMING_PARTNERS = 'leastperformingpartners',
	TOP_COMPLETE_MDES = 'topcompletedmdes',
	LEAST_IN_COMPLETE_MDES = 'leastincompletemdes',
	LEAST_MEMBERS_WITH_INCOMPLETE_MDE = 'leastmemberswithincompletemde',
	//
	ADD_VITALS = 'addvitals',
	EXPORT_PDF = 'exportpdf',
	EXPORT_VITALS = 'exportvitals',
	STATISTICS = 'statistics',
	VITAL_READING = 'vitalreadings',
	VITAL_WIDGETS = 'vitalwidgets',
	ADD_NOTE = 'addNote',
	//  ADMIN DASHBOARD SECTIONS
	GOALS = 'goals', // also used in PM DASHBOARD SECTIONS
	ALERTS = 'alerts',
	CLAIMS = 'claims',
	MDE_ANALYSIS = 'mdeanalysis', // also used in PM DASHBOARD SECTIONS
	STAR_PARTNERS = 'starpartners', // also used in PM DASHBOARD SECTIONS
	//  PM DASHBOARD SECTIONS
	DEMOGRAPHIC_DETAILS = 'demographicdetails',
	SESSIONS = 'sessions',
	PM_DASHBOARD_TILES = 'pmdashboardtiles',
	//  Admin dashboard tiles
	TOTAL_MEMBERS = 'totalmembers',
	TOTAL_REFERRALS = 'totalreferrals',
	TOTAL_SESSIONS = 'totalsessions',
	ALL_PROGRAM = 'allprograms',
	ADMIN_DASHBOARD_TILES = 'admindashboardtiles',
	//  Epi dashboard
	EPI_MDE_TILES = 'epimdetiles',
	//  Provider (Member screen) dashboard
	PROVIDER_DASHBOARD = 'providerdashboard',
	//  Task Module Sections
	ASSIGNED_BY_ME = 'assignedbyme',
	ASSIGNED_TO_ME = 'assignedtome',
	ASSIGN_TASKS = 'assigntasks',
	CREATE_TASKS = 'createtasks',
	TASK_LIBRARY = 'tasklibrary',
	TASK_TRACKING = 'tasktracking',
	TASK_STATUS = 'taskstatus',
	CALL_TRACKING = 'calltracking',
	CALL_LISTING = 'callslisting',
	TASK_FOLLOWUPS = 'taskfollowups',
	TASK_TILES = 'tasktiles',
	TASK_DETAILS = 'taskdetails',
	FOLLOWUPS = 'followups',
	NOTES = 'notes',
	START_TASK = 'starttask',
	TASK_CALENDAR_WIDGET = 'taskcalendarwidget',
	TASK_CPTS_GRID = 'taskdetailscptgrid',
	CALL_DETAIL = 'calldetail',
}
// Permission used
export enum PERMISSION_NAME {
	ADD = 'add',
	EDIT = 'edit',
	DELETE = 'delete',
	CLONE = 'clone',
	ASSIGN = 'assign',
	EXPORT_PDF = 'exportpdf',
	EXPORT_EXCEL = 'exportexcel',
	BOOKAPPMT = 'bookappointment',
}

//table count detail
export enum PAGINATION_LABEL {
	SEARCHED_CLIENTS = 'Searched Clients',
	SEARCHED_PROVIDERS = 'Searched Providers',
	SEARCHED_PARTNERS = 'Searched Partners',
	SEARCHED_REFERALS = 'Searched Referrals',
	SEARCHED_PROGRAMS = 'Searched Programs',
	SEARCHED_MEMBERS = 'Searched Members',
	SEARCHED_CLAIMS = 'Searched Claims',
	SEARCHED_GROUPS = 'Searched Groups',
	SEARCHED_SUMMARY_REPORT = 'Searched SummaryReport',
	SEARCHED_GLOBAL_PROGRAM = 'Searched Global Program',
	SEARCHED_ALERT = 'Searched Alert',
	SEARCHED_JOURNAL_NOTES = 'Searched Journal Notes',
	SEARCHED_DEVICE = 'Searched Devices',
	SEARCHED_CONDITION = 'Searched Conditions',
	SEARCHED_TASK = 'Searched Task',
	SEARCHED_SCREENING = 'Searched Screening',
	SEARCHED_GLOBAL_SESSIONS = 'Searched Global Sessions',
	SCREENING = 'Screening',
	CLIENTS = 'Clients',
	PROVIDERS = 'Providers',
	PARTNERS = 'Partners',
	REFERALS = 'Referrals',
	PROGRAMS = 'Programs',
	MEMBERS = 'Members',
	CLAIMS = 'Claims',
	GROUPS = 'Groups',
	SUMMARY_REPORT = 'Summary Report',
	GLOBAL_PROGRAMS = 'Global Programs',
	ALERTS = 'Alerts',
	JOURNAL_NOTES = 'Journal Notes',
	DEVICES = 'Devices',
	CONDITIONS = 'Conditions',
	TASKS = 'Task',
	SMBP_REPORTS = 'Reports',
	SEARCHED_SESSIONS = 'Searched Sessions',
	SESSIONS = 'Sessions',
	GLOBAL_SESSIONS = 'Global Sessions',
	AUDITS = 'Audits',
	SEARCHED_AUDITS = 'Searched Audits',
	POINT_OF_CONTACTS = 'Point Of Contacts',
	SEARCHED_POINT_OF_CONTACTS = 'Searched Point Of Contacts',
	GOALS = 'Goals',
	SEARCHED_GOALS = 'Searched Goals',
}

export enum LOCAL_STORAGE {
	PERMISSION_DETAIL = 'permissionDetail',
	SERVICE_NAME = 'serviceName',
	TOKEN = 'token',
	INHERITED_LOGIN = 'InheritedLogin',
	PROVIDER_SERVICES_DETAIL = 'providerServicesDetail',
}
export enum EVENT_NAME {
	UPDATE_ROUTES = 'updateRoutes',
	ONLINE = 'online',
	OFFLINE = 'offline',
	RESIZE = 'resize',
	BEFORE_UNLOAD = 'beforeunload',
	CONNECT = 'connect',
	CONNECT_ERROR = 'connect_error',
	DISCONNECT = 'disconnect',
}

export enum SERVICE_NAME {
	DASHBOARD = 'DASHBOARD',
	AWV = 'AWV',
	CCM = 'CCM',
	MTM = 'MTM',
	WELLNESS = 'WELLNESS',
	PUBLIC_HEALTH = 'PUBLIC HEALTH',
}
export enum FILE_UPLOAD_MESSAGES {
	SUCCESS = 'File Uploaded Successfully',
	ERROR = 'Something went wrong',
	DELETE = 'File deleted successfully',
}

export enum DROPDOWN_LIMIT {
	LIMIT = 10,
	GROUP_LIMIT = 30,
}
export enum DEFAULT_ROUTE {
	MEMBERS = '/members',
}
export enum MEMBER_DROPDOWN_LIMIT {
	LIMIT = 100,
}
export enum DROPDOWN_PAGE {
	PAGE = 1,
}

export enum SOCKET_PORT {
	PORT = 3010,
}
export enum DEFAULT_CLIENT {
	CLIENT_NAME = 'carehalowest',
}
export enum DATE_FORMAT {
	DDD_MMM_D_YYYY = 'ddd, MMM D, YYYY',
	MMMM = 'MMMM',
	YYYY_MM_DDTHH_MM_SS = 'YYYY-MM-DDTHH:mm:ss',
}
export enum ACTIVITY_ACTION_NAME {
	PAGE_VISIT = 'page_visit',
	BUTTON_CLICK = 'button_click',
	FILTER_SECTION_ACTIVITY = 'filter_section_activity',
}

export enum DOWNLOAD_FILE_NAME {
	TASK_REPORT_PDF = 'task-report.pdf',
	TASK_REPORT_XLSX = 'task_report.xlsx',
	PATIENTS_VITALS = 'patients-vitals.pdf',
}
