import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
	useMutation,
	UseMutationOptions,
	useQueryClient,
	useQuery,
	UseQueryOptions,
} from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { FormikHelpers } from 'formik'
import axiosLibrary, { AxiosResponse } from 'axios'
import { useUrlQuery } from 'hooks/url'
import { errorNotify, successNotify } from 'utils/toster'
import { ENDPOINTS } from 'const/endpoints'
import { scrollToError } from 'utils/common'
import { commonMessages } from 'utils/messages'
import { IFullSession, ISessionParams } from '../Session.interfaces'
import { SessionInitialState, SessionPayload } from './Session.types'
import {
	cancelFileUpload,
	createSession,
	fileUpload,
	getProgramViewSession,
	getSuggestionSession,
	updateProgramSession,
} from '../Session.service'
import { getClientDetails } from 'hooks/common'
import { IAxiosResponseWithPagination } from 'types/entities'
import axios from 'axios'

//* Formik useInitial hook
export function useInitial() {
	/**
	 * * Variables and hooks section
	 */
	const alreadyMounted = useRef(false)
	const [locationQuery] = useUrlQuery<any>()
	const id = locationQuery?.suggestionSession || locationQuery?.session
	const { data, isFetched, isFetching } = useProgramViewSession({
		id,
		type: locationQuery?.suggestionType,
		clientId: getClientDetails()?.clientId,
		suspense: !id, //TO USE SUSPENSE ONCE ON MOUNT
	})
	/**
	 * *useEffect Section
	 * @returns useEffect
	 */
	useEffect(() => {
		alreadyMounted.current = true
	}, [isFetched, isFetching])
	/**
	 * *useMemo Section
	 */
	return useMemo(() => {
		const session = new SessionInitialState(
			id ? { ...data?.data, isFetched: !isFetched || isFetching } : { isFetched: false },
		)
		return session
	}, [data?.data, isFetching, id, isFetching, open])
}
//* Formik useSubmitHandler hook
export function useSubmitHandler(props: any) {
	/**
	 * * Variables and hooks section
	 */
	const [urlQuery] = useUrlQuery<any>()
	const params = useParams<{ id: string }>()
	const id = params.id
	const navigate = useNavigate()
	const client = useQueryClient()
	/**
	 * * State section
	 */
	const [sessionId, setSessionId] = useState()

	//  not in used for now
	// const { isGlobalProgram } = useContext(DataContext)
	// const programProfileUrl = useGetProgramProfileScreenSlug(isGlobalProgram)
	// const programSessionPath = useGetProgramSessionScreenSlug(isGlobalProgram)

	/**
	 * * 1. Using useCreateSession() hook to create a mutateAsync function for creating a new session.
	 * * 2. Using useUpdateSession() hook to create a mutateAsync function for updating an existing session, with the id specified in the params.
	 */
	const { mutateAsync: create } = useCreateSession({ clientId: getClientDetails()?.clientId })
	const { mutateAsync: update } = useUpdateSession({
		id: sessionId,
		clientId: getClientDetails()?.clientId,
	})
	/**
	 * *useEffect Section
	 * @returns useEffect
	 */
	useEffect(() => {
		setSessionId(props.sessionId)
	}, [props])
	/**
	 * * useCallback section
	 */
	return useCallback(
		async (form: SessionInitialState, formikHelpers: FormikHelpers<SessionInitialState>) => {
			formikHelpers.setSubmitting(true)
			form.program = id
			form.existingId = urlQuery?.suggestionSession

			try {
				if (!sessionId) {
					// if(form?.search){
					// 	createSuggestionSession
					// }
					await create(new SessionPayload(form))
				} else {
					await update(new SessionPayload(form))
				}
				client.invalidateQueries([ENDPOINTS.SESSIONS])
				successNotify(sessionId ? commonMessages.updateMessage : commonMessages.createMessage)
				formikHelpers.resetForm()
				props.setAddSession(false)
				formikHelpers.setSubmitting(false)

				// navigate(`${programProfileScreenPath}/${id}/${programVitalsScreenPath}`)
				// navigate(APP_ROUTES.PROGRAM_PROFILE + `/${id}` + `/sessions`)
			} catch (err: any) {
				if (err.response.data && err.response.data.message) {
					errorNotify(err.response.data.message)
					// Somewhere else, even another file
				} else {
					formikHelpers.setErrors(err.response.data) //TODO - ADD ERRORS TRANSFORMATION TO FORM FORMAT
					errorNotify(commonMessages.errorHandlingMessage)
				}
				scrollToError()
			}
		},
		[client, create, id, navigate, update, sessionId, urlQuery],
	)
}

/**
 * * Using the React Hooks useMutation to create a mutation function for creating a session.
 */
type ICreateSessionProps = Partial<
	UseMutationOptions<AxiosResponse<SessionPayload>, any, Partial<SessionPayload>>
>

export function useCreateSession({ clientId, ...rest }: ICreateSessionProps | any = {}) {
	return useMutation<AxiosResponse<SessionPayload>, any, Partial<SessionPayload>>(
		async (data) => await createSession(clientId, data),

		{
			...rest,
		},
	)
}
/**
 * * Using the React Hooks useMutation to update a mutation function for update a session.
 */
interface IUpdateSessionProps
	extends Partial<UseMutationOptions<AxiosResponse<SessionPayload>, any, Partial<SessionPayload>>> {
	id?: string
	clientId?: string
}

export function useUpdateSession({ id, clientId, ...rest }: IUpdateSessionProps) {
	return useMutation<AxiosResponse<SessionPayload>, any, Partial<SessionPayload>>(
		async (data) => await updateProgramSession(id, clientId, data),
		{
			...rest,
		},
	)
}

/**
 * Using the React Hooks useMutation for file upload
 */

export function useFileUpload({
	fileId,
	startChunk,
	endChunk,
	fileSize,
	clientId,
	totalChunks,
	totalChunksUploaded,
	...rest
}: any) {
	return useMutation<AxiosResponse<any>, any, any>(
		async (data) => {
			return await fileUpload(
				fileId,
				startChunk,
				endChunk,
				fileSize,
				clientId,
				totalChunks,
				totalChunksUploaded,
				data,
			)
		},
		{
			...rest,
		},
	)
}

export function useCancelFileUpload({ session, clientId }: { session?: string; clientId: string }) {
	//@ts-ignore
	const CancelToken = axiosLibrary.CancelToken
	const source = CancelToken.source()
	return useMutation<AxiosResponse<any>, any, any>(
		async (id: string) => await cancelFileUpload(clientId, { path: id, session: session }, source),
	)
}

/**
 * * Using the React Hooks useQuery to update a Query function for view a session.
 */
interface IProgramViewSession
	extends Partial<UseQueryOptions<AxiosResponse<IFullSession>>>,
		Partial<IFullSession> {
	id?: string
	clientId?: string
}
export function useProgramViewSession({ id, clientId, ...rest }: IProgramViewSession) {
	return useQuery<AxiosResponse<IFullSession>, any, AxiosResponse<IFullSession>>(
		[ENDPOINTS.SESSION_ENTITY, id, clientId],
		() => {
			const CancelToken = axiosLibrary.CancelToken
			const source = CancelToken.source()
			return getProgramViewSession(id, clientId, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: true,
			enabled: !!id,
			...rest,
		},
	)
}

interface ISuggestionSessionQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<IFullSession>>>,
		Partial<ISessionParams> {
	isEnabled?: boolean
}

export function useProgramSuggestionSession({
	type,
	typeId,
	title,
	duration,
	toBeCompleted,
	frequency,
	questionnaireId,
	page,
	sort,
	limit,
	search,
	programId,
	isEnabled = true,
	id,
	...rest
}: ISuggestionSessionQueryProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<IFullSession>>,
		any,
		IAxiosResponseWithPagination<Partial<IFullSession>>
	>(
		[ENDPOINTS.SESSIONS, page, sort, limit, search, id, typeId, programId, type],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getSuggestionSession(
				{
					type,
					typeId,
					title,
					duration,
					toBeCompleted,
					frequency,
					questionnaireId,
					page,
					sort,
					limit,
					search,
					programId,
				},
				source,
				// id,
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			suspense: false,
			enabled: !!typeId && isEnabled,
			...rest,
		},
	)
}
