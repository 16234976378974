import { ISelectOption } from 'types/forms'
import { SelectOption } from 'types/entities'
import { convertUnix, formatDate } from 'utils/time'
import { IFullConditions } from './MemberCondition.interface'

export class ConditionPayload {
	condition: string | null = null
	onSetDate: number | any
	assignee?: string | null = null
	description: string | null = null
	patient: string | null | undefined = null

	constructor({ ...data }: Partial<ConditionInitialState> = {}) {
		this.addCondition(data?.condition)
		this.addOnSetDate(data?.onSetDate)
		this.addAssignee(data?.assignee)
		this.addDescription(data?.description)
	}

	addCondition(condition: any) {
		if (condition) {
			this.condition = condition.value
		}
	}
	addOnSetDate(onSetDate: number | any) {
		if (onSetDate) {
			this.onSetDate = convertUnix(onSetDate)
		}
	}
	addAssignee(assignee: any) {
		if (assignee) {
			this.assignee = assignee.value
		}
	}
	addDescription(description: string | null = null) {
		if (description) {
			this.description = description
		} else {
			this.description = ''
		}
	}
}

export class ConditionInitialState {
	condition: ISelectOption | null | any
	onSetDate: string | any
	assignee?: ISelectOption | null | any
	description: string | null = null
	patient: string | null | undefined = null

	constructor({ ...data }: Partial<IFullConditions> = {}) {
		this.addCondition(data?.condition)
		this.addOnSetDate(data?.onSetDate)
		this.addAssignee(data?.assignee)
		this.addDescription(data?.description)
	}

	addCondition(condition: string | any) {
		if (condition) {
			this.condition = new SelectOption(condition.id, condition.condition)
		}
	}
	addOnSetDate(onSetDate: string | any) {
		if (onSetDate) {
			const date = formatDate(+onSetDate)
			this.onSetDate = date
		}
	}
	addAssignee(assignee: string | any) {
		if (assignee) {
			this.assignee = new SelectOption(assignee?.id, assignee?.humanName?.text)
		}
	}
	addDescription(description: string | null = null) {
		if (description) {
			this.description = description
		}
	}
}
