import { CancelTokenSource } from 'axios'
import { ISlotsData, PartnerSlotsPayload } from './Slots.constants'
import axios from 'services/axios'
import { ENDPOINTS } from 'const/endpoints'
import _ from 'lodash'
import { commonMessages } from 'utils/messages'
import { INonHeathCarePartners } from 'services/requests'
import { IPagination } from 'types/entities'

export function getSlotsData(
	id: string | number | undefined,
	clientId: string | undefined,
	source?: CancelTokenSource,
	params?: Partial<ISlotsData>,
) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get<ISlotsData>(
		id
			? ENDPOINTS.PARTNER_CALENDER + '/' + clientId + '/' + id
			: ENDPOINTS.PARTNER_CALENDER + '/' + clientId,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function updatePartnerSlots(
	clientId: string | undefined,
	data: Partial<PartnerSlotsPayload>,
	source?: CancelTokenSource,
) {
	const promise = axios.post<PartnerSlotsPayload>(ENDPOINTS.PARTNER_SLOTS + '/' + clientId, data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function getAllProviderClientsForSlotContainer(
	params: Partial<INonHeathCarePartners>,
	id: string | number | undefined,
	clientId: string | number | undefined,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<INonHeathCarePartners>>>(
		ENDPOINTS.PARTNER_PROVIDER + '/' + clientId + '/' + id,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
export function getAllPartnerClientsForSlotContainer(
	params: Partial<INonHeathCarePartners>,
	id: string | number | undefined,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<INonHeathCarePartners>>>(
		ENDPOINTS.CLIENT_PARTNER + '/' + id,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function getRoleCalenderMember(
	params: Partial<INonHeathCarePartners>,
	id: string | number | undefined,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<INonHeathCarePartners>>>(
		ENDPOINTS.MEMBER_PROVIDER_CLIENTS + '/' + id,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function getSlotContainerMembers(
	clientId: string | undefined,
	params: Partial<INonHeathCarePartners>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get<IPagination<Partial<any>>>(
		ENDPOINTS.SLOT_CONTAINER_MEMBERS + '/' + clientId,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise //promise
}
