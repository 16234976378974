import { AdvancedSelectField } from 'components/AdvancedSelectField'
import { IAdvancedSelectDropdownProps } from './AdvancedScrollSelectDropdown.interface'
import { useEffect, useRef } from 'react'
import { InputActionMeta } from 'react-select'
import { createSearchHandler } from 'hooks/common'

const AdvancedScrollSelectDropdown = ({
	options,
	isFetching,
	makeSearch,
	value,
	onChange,
	setPage,
	placeholder = '',
	classNames = {},
	closeMenuOnSelect = true,
	label = '',
	className = '',
	isClearable = false,
	isSearchable = true,
	disabled = false,
	totalPages = 0,
	required = false,
	page = 0,
	search = '',
	setSearch,
	...rest
}: IAdvancedSelectDropdownProps) => {
	// Hooks Section
	const totalDropdownPagesRef = useRef(0)
	const currentPageRef = useRef(1)
	const isDataFetchingRef = useRef(true)
	const makeSearchCommon = createSearchHandler(
		setSearch as React.Dispatch<React.SetStateAction<string>>,
		setPage,
	)

	//Functions Section
	const onScrollDown = () => {
		const totalDropdownPages = totalDropdownPagesRef.current
		const currentPage = currentPageRef.current
		const isDataFetchingRefValue = isDataFetchingRef.current
		if (totalDropdownPages > currentPage && !isDataFetchingRefValue) {
			const currentPageValue = currentPage + 1
			currentPageRef.current = currentPageValue
			setPage(currentPageValue)
		}
	}

	const handleInputChange = (inputValue: string, { action }: InputActionMeta) => {
		if (action === 'input-blur' && search) {
			makeSearch ? makeSearch(inputValue) : makeSearchCommon(inputValue)

			currentPageRef.current = 1
		} else if (action !== 'input-blur' && action !== 'menu-close') {
			makeSearch ? makeSearch(inputValue) : makeSearchCommon(inputValue)
		}
	}
	// useEffect Section
	useEffect(() => {
		if (totalPages) {
			totalDropdownPagesRef.current = totalPages
		}
	}, [totalPages])

	useEffect(() => {
		if (page !== currentPageRef.current) {
			currentPageRef.current = page
		}
	}, [page])

	useEffect(() => {
		isDataFetchingRef.current = isFetching
	}, [isFetching])

	useEffect(() => {
		return () => {
			currentPageRef.current = 1
			totalDropdownPagesRef.current = 0
			isDataFetchingRef.current = true
		}
	}, [])

	return (
		<>
			<AdvancedSelectField
				isLoading={isFetching}
				label={label}
				placeholder={placeholder}
				value={value}
				onInputChange={handleInputChange}
				options={options}
				onCustomMenuScrollToBottom={onScrollDown}
				onChange={onChange}
				closeMenuOnSelect={closeMenuOnSelect}
				className={className}
				isClearable={isClearable}
				isSearchable={isSearchable}
				classNames={classNames}
				isDisabled={disabled}
				{...rest}
			/>
		</>
	)
}

export default AdvancedScrollSelectDropdown
