import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { UseMutationOptions, useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import axios, { AxiosResponse } from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import { useUrlQuery } from 'hooks/url'
import { createCareTeam, getCareTeamAdd } from './MemberCareTeam.service'
import { CareTeamInitialState, CareTeamPayload } from './MemberCareTeam.types'
import { errorNotify, successNotify } from 'utils/toster'
import { commonMessages } from 'utils/messages'
import { scrollToError } from 'utils/common'
import { getClientDetails } from 'hooks/common'
import { FormikHelpers } from 'formik'

/**
 * * Formik useInitial Section
 */
export function useInitial() {
	/**
	 * * Variable and hooks section
	 */
	const alreadyMounted = useRef(false)
	const [locationQuery] = useUrlQuery<any>()
	const id = locationQuery
	/**
	 * *useEffects Section
	 * @param useEffectName
	 */
	useEffect(() => {
		alreadyMounted.current = true
	}, [])
	/**
	 * * useMemo section
	 */
	return useMemo(() => {
		return new CareTeamInitialState(id ? id : {})
	}, [id])
}
/**
 * * Formik useSubmit Section
 */
export function useSubmitHandler(props: any) {
	/**
	 * * Variable and hooks section
	 */
	const params = useParams<{ id: string }>()
	const id = params?.id
	const navigate = useNavigate()
	const client = useQueryClient()
	const { mutateAsync: create } = useCreateMemberCareTeam({
		clientId: getClientDetails()?.clientId,
	})
	/**
	 * * State section
	 */
	const [patientId, setPatientId] = useState<any>()
	/**
	 * *useEffects Section
	 * @param useEffectName
	 */
	useEffect(() => {
		setPatientId(id)
	}, [props])
	/**
	 * * useCallback section
	 */
	return useCallback(
		async (form: CareTeamInitialState, formikHelpers: FormikHelpers<CareTeamInitialState>) => {
			form.patient = patientId
			formikHelpers.setSubmitting(true)
			try {
				await create(new CareTeamPayload(form))
				formikHelpers.resetForm({
					values: {
						careTeam: [],
						patient: null,
					} as any,
				})

				client.invalidateQueries([ENDPOINTS.PATIENTS_CARE_TEAM])
				client.invalidateQueries([ENDPOINTS.PROVIDERS_CARE_TEAM])
				successNotify(commonMessages.createMessage)
			} catch (err: any) {
				if (err.response.data && err.response.data.message) {
					errorNotify(err.response.data.message)
					// Somewhere else, even another file
				} else {
					formikHelpers.setErrors(err.response.data) //TODO - ADD ERRORS TRANSFORMATION TO FORM FORMAT
					errorNotify(commonMessages.errorHandlingMessage)
				}
				scrollToError()
			}
		},
		[client, create, id, navigate, patientId],
	)
}

/**
 * * The useMemberDevices  function is a React hook that takes in a set of query parameters.
 */
export function useCareTeamAdd({
	id,
	clientId,
	limit,
	page,
	search,
}: {
	id: string | undefined
	clientId: string | undefined
	limit?: number
	page?: number | undefined
	search?: string
}) {
	return useQuery(
		[ENDPOINTS.PROVIDERS_CARE_TEAM, id, clientId, limit, page, search],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getCareTeamAdd({ id }, clientId, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			// enabled: params?.addCpt ? true : false,
		},
	)
}

type ICreateCptProps = Partial<
	UseMutationOptions<AxiosResponse<CareTeamPayload>, any, Partial<CareTeamPayload>>
>
export function useCreateMemberCareTeam({ clientId, ...rest }: ICreateCptProps | any = {}) {
	return useMutation<AxiosResponse<CareTeamPayload>, any, Partial<CareTeamPayload>>(
		async (data) => await createCareTeam(clientId, data),

		{
			...rest,
		},
	)
}
