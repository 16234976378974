import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { ISlotsData, PartnerSlotsPayload } from './Slots.constants'
import axios from 'axios'
import { AxiosResponse } from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import {
	getAllPartnerClientsForSlotContainer,
	getAllProviderClientsForSlotContainer,
	getRoleCalenderMember,
	getSlotContainerMembers,
	getSlotsData,
	updatePartnerSlots,
} from './Slots.service'
import { IAxiosResponseWithPagination } from 'types/entities'
import { INonHeathCarePartners } from 'services/requests'
import { getClientDetails } from 'hooks/common'

interface ISlotsDataProps
	extends Partial<UseQueryOptions<AxiosResponse<ISlotsData>>>, //response data
		Partial<ISlotsData> {} //request data

export function useSlotsData({ id, clientId, startTime, endTime, ...rest }: ISlotsDataProps = {}) {
	return useQuery<AxiosResponse<ISlotsData>, any, AxiosResponse<ISlotsData>>(
		[ENDPOINTS.PARTNER_CALENDER, id, startTime, endTime, clientId],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getSlotsData(id, clientId, source, {
				startTime,
				endTime,
			})
		},
		{
			suspense: false,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!startTime && !!endTime && !!id,
			...rest,
		},
	)
}

export function useUpdatePatientSlots({ clientId }: { clientId: string }) {
	return useMutation<AxiosResponse<PartnerSlotsPayload>, any, Partial<PartnerSlotsPayload>>(
		async (data) => await updatePartnerSlots(clientId, data),
	)
}
interface ICalenderProviderListQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<INonHeathCarePartners>>>,
		Partial<INonHeathCarePartners> {}
export function useAllProviderClientListForSlotontainer({
	clientId,
	page,
	limit,
	id,
	sort,
	search,
	status,
	...rest
}: ICalenderProviderListQueryProps) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<INonHeathCarePartners>>,
		any,
		IAxiosResponseWithPagination<Partial<INonHeathCarePartners>>
	>(
		[ENDPOINTS.PARTNER_PROVIDER, page, limit, sort, status, search, id, clientId],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getAllProviderClientsForSlotContainer(
				{ page, limit, sort, status, search },
				id,
				clientId,
				source,
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!id,
			...rest,
		},
	)
}

export function useAllPartnerClientListForSlotContainer({
	page,
	limit,
	id,
	sort,
	search,
	status,
	...rest
}: ICalenderProviderListQueryProps) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<INonHeathCarePartners>>,
		any,
		IAxiosResponseWithPagination<Partial<INonHeathCarePartners>>
	>(
		[ENDPOINTS.CLIENT_PARTNER, page, limit, sort, status, search, id],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getAllPartnerClientsForSlotContainer({ page, limit, sort, status, search }, id, source)
		},
		{
			suspense: false,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!id,
			...rest,
		},
	)
}

// usePatientsList({ ...params })

interface ICalenderRoleListQueryProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<INonHeathCarePartners>>>,
		Partial<INonHeathCarePartners> {}
export function useAllPatientRoleClientListRole({
	page,
	limit,
	id,
	sort,
	search,
	status,
	...rest
}: ICalenderRoleListQueryProps) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<INonHeathCarePartners>>,
		any,
		IAxiosResponseWithPagination<Partial<INonHeathCarePartners>>
	>(
		[ENDPOINTS.MEMBER_PROVIDER_CLIENTS, page, limit, sort, status, search, id],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getRoleCalenderMember({ page, limit, sort, status, search }, id, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!id,
			...rest,
		},
	)
}
interface IPatientsListProps
	extends UseQueryOptions<IAxiosResponseWithPagination<Partial<any>>>, //response time
		Partial<INonHeathCarePartners> {
	isEnabled?: boolean
} //request time
export function useSlotContainerMembers({
	clientId = getClientDetails()?.clientId,
	id,
	page,
	limit,
	sort,
	name,
	partner,
	search,
	status,
	provider,
	isEnabled = true,
	...rest
}: IPatientsListProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<Partial<any>>,
		any,
		IAxiosResponseWithPagination<Partial<any>>
	>(
		[
			ENDPOINTS.SLOT_CONTAINER_MEMBERS,
			page,
			limit,
			sort,
			name,
			search,
			partner,
			status,
			id,
			provider,
			clientId,
		],
		(): any => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getSlotContainerMembers(
				clientId,
				{ page, limit, sort, name, status, search, provider, partner },
				source,
			)
		},
		{
			keepPreviousData: false,
			refetchOnWindowFocus: false,
			enabled: !!provider && isEnabled,
			...rest,
		},
	)
}
