import _ from 'lodash'
import { CancelTokenSource } from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import axios from 'services/axios'
import { IPagination } from 'types/entities'
import { commonMessages } from 'utils/messages'
import { IReferralParams, TableFilters } from './Referrals.constants'
import { onCreateEndpointsWithClientid, toFormData } from 'utils/common'

/**
 * Get All Patients for Referral
 */
export function getPatientsForReferrals(source?: CancelTokenSource, clientId?: string | undefined) {
	const promise = axios.get(ENDPOINTS.ALL_MEMBER_FOR_REFERRAL + '/' + clientId)

	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function getPartnerForReferrals(
	source?: CancelTokenSource,
	id?: string | undefined,
	params?: Partial<{ client: string; member: string }>,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get(ENDPOINTS.PARTNER_CLIENTS + '/' + id, {
		params: filteredParams,
	})

	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * Get Partner Scheduler Referral
 */

export function getPartnerSchedulerReferral(id: string | undefined, source?: CancelTokenSource) {
	const promise = axios.get<any>(ENDPOINTS.PARTNER_SCHEDULER + '/' + id)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * Get Partner Speciality
 */
export function getPartnerSpeciality(
	clientId?: string | undefined,
	source?: CancelTokenSource,
	params?: Partial<{ id: string }>,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = params?.id
		? axios.get(ENDPOINTS.PATIENT_SPECILIZATION + '/' + clientId, {
				params: filteredParams,
		  })
		: axios.get(ENDPOINTS.PATIENT_SPECILIZATION + '/' + clientId)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * Get Referral Conditions
 */
export function getReferralConditions(
	source?: CancelTokenSource,
	params?: Partial<{
		search: string
		page: number
		// limit: number
	}>,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get(ENDPOINTS.PROGRAM_CONDITION, {
		params: filteredParams,
	})

	//@ts-ignore
	// Add cancel method to the promise
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}

/**
 * Get Providers Doctor Specialities
 */
export function getProvidersDoctorSpeciality(
	id: string,
	source?: CancelTokenSource,
	params?: Partial<{
		search: string
		limit: number
		partner: string | number
		member: string | number
		page: number
	}>,
) {
	const filteredParams = _.pickBy(params, _.identity)
	// const promise = axios.get(ENDPOINTS.PROVIDER_ROLE_ALL + '/' + id, {
	// 	params: filteredParams,
	// })
	const promise = axios.get(
		onCreateEndpointsWithClientid(ENDPOINTS.PROVIDER_UNDER_SPECIALIZATION) + '/' + id,
		{
			params: filteredParams,
		},
	)

	//@ts-ignore
	// Add cancel method to the promise
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}

/**
 * Create Referral
 */
export function createReferral(
	clientId?: string | undefined,
	data?: Partial<any>,
	source?: CancelTokenSource,
) {
	if (!data?.partner) {
		delete data?.partner
	}
	if (!data?.document) {
		delete data?.document
	}
	const promise = axios.post<any>(ENDPOINTS.REFERRAL + '/' + clientId, data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * File-Upload Referral
 */

// export function fileUploadReferral(
// 	clientId: string | undefined,
// 	data: any,
// 	source?: CancelTokenSource,
// ) {
// 	const promise = axios.post<any>(ENDPOINTS.AZURE_FILE_UPLOAD + '/' + clientId, toFormData(data))
// 	//@ts-ignore
// 	promise.cancel = () => {
// 		source?.cancel(commonMessages.queryCancelledMessage)
// 		source?.cancel(commonMessages.queryCancelledMessage)
// 	}
// 	return promise
// }

/**
 * Get Single Referral
 */

export function getSingleReferral(
	id: any,
	clientId: string | undefined,
	source?: CancelTokenSource,
) {
	const promise = axios.get<any>(ENDPOINTS.REFERRAL + '/' + clientId + '/' + id)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * Update Referral
 */
export function updateReferral(
	id: string | undefined,
	clientId: string | undefined,
	data: Partial<any>,
	source?: CancelTokenSource,
) {
	if (!data?.partner) {
		delete data?.partner
	}
	if (!data?.document) {
		delete data?.document
	}
	const promise = axios.patch<any>(ENDPOINTS.REFERRAL + '/' + clientId + '/' + id, data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * Get Refferals List
 */
export function getReferralsList(
	source?: CancelTokenSource,
	id?: string | undefined,
	client?: string | undefined,
	params?: Partial<TableFilters>,
) {
	const filteredParams = _.pickBy(params, _.identity)
	if (params?.provider) {
		delete filteredParams?.client
		delete filteredParams?.partner
	}
	const promise = axios.get<IPagination<Partial<IReferralParams>>>(
		ENDPOINTS.REFERRAL_LIST + '/' + client,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

// Get All Provider in behalf of clients referrals
export function getAllProviderReferral(
	clientId: string | undefined,
	params: Partial<any>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)
	if (params?.partner) {
		delete filteredParams?.client
	}
	const promise = axios.get<IPagination<Partial<any>>>(
		ENDPOINTS.PROVIDERS_DOCTOR_REFERRAL + '/' + clientId,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
export function fileUploadReferral(
	clientId: string | undefined,
	data: any,
	source?: CancelTokenSource,
) {
	const promise = axios.post<any>(
		onCreateEndpointsWithClientid(ENDPOINTS.AZURE_FILE_UPLOAD),
		toFormData(data),
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * File delete Referral
 */

export const deleteUploadFile = (
	clientId: string | undefined,
	imgName: any,
	source?: CancelTokenSource,
) => {
	const image = imgName.split('/').pop()
	const promise = axios.delete<any>(
		onCreateEndpointsWithClientid(ENDPOINTS.AZURE_FILE_DELETE) + '/' + image,
		{},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
