/* eslint-disable @typescript-eslint/ban-ts-comment */
import { CancelTokenSource } from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import _ from 'lodash'
import { IResetPasswordData, IResetPasswordEmail } from 'types/auth'
import {
	IAudit,
	IPagination,
	IPostalCode,
	IFullPatient,
	IFullVital,
	IVitalReport,
	IJournalNote,
	IFullJournalNote,
	IFullNotification,
	IItems,
	IFullTasks as IFullTasks,
	IGlobalCodes,
} from 'types/entities'
import axios from './axios'
import { IFullPartner } from 'modules/Partners/Partner.interfaces'
import { IFullProvider } from 'modules/Providers/Provider.interfaces'
import {
	IFullPoc,
	IPocParams,
} from 'modules/Partners/Partner/PointOfContacts/PointOfContact.interfaces'
import { commonMessages } from 'utils/messages'
import { IFullAddSchedule } from 'modules/Partners/Partner/Schedules/Schedules.interface'
import { onCreateEndpointsWithClientid } from 'utils/common'

export function getMe(source?: CancelTokenSource) {
	const promise = axios.get<IFullProvider>(ENDPOINTS.ME)
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
export function getAllGlobalCodes(clientId: string | null = '', source?: CancelTokenSource) {
	const promise = axios.get<IGlobalCodes[]>(ENDPOINTS.GET_GLOBAL_CATEGORY_ALL + '/' + clientId)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function createAudit(data?: Partial<IAudit>, source?: CancelTokenSource) {
	const promise = axios.post<IAudit>(onCreateEndpointsWithClientid(ENDPOINTS.AUDITS), data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
export interface IAuditsParams {
	id: string
	page: number
	limit: number
	auditGroup: string
	sort: string
	startDate: string
	endDate: string
	credentials: string
}
export function getAudits(
	params: Partial<IAuditsParams>,
	id: string | undefined,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get<IPagination<IAudit>>(ENDPOINTS.AUDITS + '/' + id, {
		params: filteredParams,
	})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function getAllVitals(
	source?: CancelTokenSource,
	clientId?: string | undefined,
	id?: string | undefined,
) {
	const promise = axios.get(`${ENDPOINTS.UNDER_PROGRAM_VITAL}/${clientId}/${id}`)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

// export interface IPartnersParams {
// 	page: number
// 	sort: string
// 	id: string
// 	limit: number
// 	partnerType: string
// 	city: string
// 	companyName: string
// 	status: string
// 	cptlist?: string | number
// }

export interface IPostalCodeParams {
	zipCode: string
}

export function getPostalCodes(params: IPostalCodeParams, source?: CancelTokenSource) {
	const getEndpointWithClientId = onCreateEndpointsWithClientid(ENDPOINTS?.ZIP_CODES)
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get<IPostalCode[]>(getEndpointWithClientId, {
		// const promise = axios.get<IPostalCode[]>(ENDPOINTS.ZIP_CODES, {
		params: filteredParams,
	})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages?.queryCancelledMessage)
	}
	return promise
}

export function getStates(source?: CancelTokenSource) {
	const promise = axios.get<string[]>(onCreateEndpointsWithClientid(ENDPOINTS.STATES))
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages?.queryCancelledMessage)
	}
	return promise
}

export function getPartnerAllPoc(
	id: string | undefined,
	source?: CancelTokenSource,
	params?: Partial<IPocParams>,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<IFullPoc>>>(
		ENDPOINTS.PARTNER_GET_ALL_POC + '/' + id,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function getPartnerScheduleWeek(id: string | undefined, source?: CancelTokenSource) {
	const promise = axios.get<string[]>(ENDPOINTS.PARTNER_SCHEDULER_WEEK + '/' + id)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
// getScheduler Slots
export function getPartnerScheduleSlots(id: string | undefined, source?: CancelTokenSource) {
	const promise = axios.get<string[]>(ENDPOINTS.PARTNER_SCHEDULER_SLOTS + '/' + id)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
export interface ReportParamsInterface {
	firstName: string
	lastName: string
	dob: string
	daysWithReading: number
	timeTrack: string
	totalReadingsReceived: string
	bloodPressure: string
	glucose: string
	temp: string
	weight: string
	pulseOx: string
}

export interface ISummeryQuery {
	clientId: string
	startDate: string
	endDate: string
}

export function getSummaryReports(params: Partial<ISummeryQuery>, source?: CancelTokenSource) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<string[]>(ENDPOINTS.SUMMARY_REPORT, {
		params: filteredParams,
	})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel('Query Cancelled by react Query')
	}
	return promise
}

// export interface IPartnersParams {
// 	page: number
// 	sort: string
// 	id: string
// 	limit: number
// 	partnerType: string
// 	city: string
// 	companyName: string
// 	search: string
// 	status: string
// }

export interface IPatientsParams {
	page: number
	sort: string
	limit: number
	name: string
	status: string
	client: string
	programName: string
	city: string
	state: string
	isAlert?: 'true' | 'false'
	untrained?: 'true' | 'false'
	lastMeasurementLt?: string
	healthCondition?: string
	search?: string
	partner?: string
	clientId?: string
	id: string
	// loading?: boolean
}
// GET_PATIENT_FOR_CHAT
export function getPatients(
	// clientId: string | undefined,
	params: Partial<IPatientsParams>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<IFullPatient>>>(
		onCreateEndpointsWithClientid(ENDPOINTS.PATIENTS),
		// ENDPOINTS.PATIENTS + '/' + clientId,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function getMemberChats(
	// clientId: string | undefined,
	params: Partial<IPatientsParams>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<IFullPatient>>>(
		onCreateEndpointsWithClientid(ENDPOINTS.GET_PATIENT_FOR_CHAT),
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

//
export function getPatient(
	id: string | undefined,
	clientId: string | undefined,
	source?: CancelTokenSource,
) {
	const promise = axios.get<IFullPatient>(`${ENDPOINTS.PATIENTS}/${clientId}/${id}`)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function sendResetPasswordRequest(data: IResetPasswordEmail) {
	return axios.post<IResetPasswordEmail>(ENDPOINTS.REQUEST_RESET_PASSWORD, {
		...data,
	})
}

export function resetPassword(data: IResetPasswordData) {
	return axios.post<IResetPasswordData>(ENDPOINTS.RESET_PASSWORD, {
		...data,
	})
}

export function getVital(id: string | undefined, source?: CancelTokenSource) {
	const promise = axios.get<IFullVital>(ENDPOINTS.VITALS + '/' + id)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export interface IJournalNoteParams {
	title: string
	details: string
	patient: string
}

export function getJournalNote(id: string | undefined, source?: CancelTokenSource) {
	const promise = axios.get<IJournalNote>(ENDPOINTS.JOURNAL_NOTES + '/' + id)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export interface IVitalParams {
	name: string
	page: number
	sort: string
	limit: number
	deviceType: string
	type: string
	takenAt: string
	onlyDeleted: boolean
	startDate: string
	endDate: string
	isAlert?: 'true'
	isCleared?: 'true' | 'false'
	patient: string
	search?: string
}
export function getVitals(params: Partial<IVitalParams>, source?: CancelTokenSource) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get<IPagination<Partial<IFullVital>>>(ENDPOINTS.VITALS, {
		params: filteredParams,
	})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export interface IVitalReportParams extends IVitalParams {
	client: string
	patient: string
}
export function getVitalsReport(params: Partial<IVitalReportParams>, source?: CancelTokenSource) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get<IVitalReport[]>(ENDPOINTS.VITALS_REPORT, {
		params: filteredParams,
	})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export interface IJournalNotesParams {
	title: string
	page: number
	limit: number
	sort: string
	startDate: string
	patient: string
	provider: string | ''
}
export function getJournalNotes(params: Partial<IJournalNotesParams>, source?: CancelTokenSource) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get<IPagination<IFullJournalNote>>(ENDPOINTS.JOURNAL_NOTES, {
		params: filteredParams,
	})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export interface INotificationParams {
	page: number
	limit: number
	sort: string
	patient: string
}

export function getNotifications(params: Partial<INotificationParams>, source?: CancelTokenSource) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get<IPagination<IFullNotification>>(ENDPOINTS.NOTIFICATIONS, {
		params: filteredParams,
	})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

// Schedule Get API

// Get Schedule Get APi
export function getSchedule(
	id: string | undefined,
	clientId: string | undefined,
	source?: CancelTokenSource,
) {
	const promise = axios.get<IFullAddSchedule>(
		ENDPOINTS.PARTNER_SCHEDULER + '/' + clientId + '/' + id,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export interface INonHeathCarePartners {
	patient?: string
	page?: number
	sort?: string
	limit?: number
	name?: string
	status?: string
	client?: string
	partner?: string
	email?: string
	id?: any
	date?: number | Date
	search?: string
	startTime?: number
	endTime?: number
	provider?: string
	humanName: {
		text: string
	}
	isBookAppointment?: any
	providerId?: any
	partnerId?: any
	clientId?: any
	patientId?: any
	urlId?: string
	patientName: string
	providerName: string
	partnerName: string
}

export interface IPartnerCalendar {
	id?: string | number
	startTime: number
	endTime: number
	patient?: string
	provider?: number | string
	providerId?: string | number
	partnerId: any
	partner?: any
	patientId?: any
	clientId?: string
}

export function getNonHealthcarePartners(
	params: Partial<INonHeathCarePartners>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IPagination<Partial<IFullPartner>>>(ENDPOINTS.NONHEATHCARE_PARTNERS, {
		params: filteredParams,
	})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function getPartnerCalendarEvents(
	params: Partial<IPartnerCalendar>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)
	delete filteredParams?.id
	const promise = axios.get<IPagination<Partial<IFullPartner>>>(
		params?.id ? ENDPOINTS.PARTNER_CALENDER + '/' + params?.id : ENDPOINTS.PARTNER_CALENDER,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function getPatientsList(
	params: Partial<INonHeathCarePartners>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get<IPagination<Partial<IFullPartner>>>(ENDPOINTS.PATIENTS, {
		params: filteredParams,
	})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise //promise
}

export interface IItemParams {
	sort: string
	limit: number
	title: string
	search: string
	clientId?: string
	programId: string
}

export function getItems(
	// clientId: string | undefined,
	params: Partial<IItemParams>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)

	const promise = axios.get<IItems<string[]>>(
		onCreateEndpointsWithClientid(ENDPOINTS.PROGRAMS_VITALS_ITEM),
		// const promise = axios.get<IItems<string[]>>(ENDPOINTS.PROGRAMS_VITALS_ITEM + '/' + clientId, {
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}

// Get API of Devices

// goals params

export interface IProgramGoalsListingParams {
	search: string
	subCategory: string
	minLimit: number
	maxLimit: number
	duration: string
	category: string
	type: string
	unit: string
	id?: string
	page: number
	sort: string
	goal?: string
	limit: number
	clientId: string
}

/**
 * tasks params
 */

export interface TaskParams {
	page: number
	limit: number
	sort: string
	patient: string
	note: any
	details: any
}

export function getTasks(params: Partial<TaskParams>, source?: CancelTokenSource) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get<IPagination<IFullTasks>>(ENDPOINTS.TASKS, {
		params: filteredParams,
	})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export interface ISummaryParams {
	page: number
	sort: string
	limit: number
	city: string
	title: string
	name: string
	state: string
	search: string
	startDate: string
	endDate: string
	client: string
}

export function updateCustom(
	id?: string | undefined,
	clientId?: string | undefined,
	data?: any,
	source?: CancelTokenSource,
) {
	const promise = axios.patch(ENDPOINTS.CUSTOM + '/' + clientId + '/' + id, data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
