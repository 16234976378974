import { useEffect, useRef } from 'react'
import { InputActionMeta } from 'react-select'
import { createSearchHandler } from 'hooks/common'
import { FormikAdvanceSelect } from 'components/FormikAdvancedSelect'
import { IFormikAdvancedSelectDropdownProps } from './AdvancedScrollSelectDropdown.interface'

const FormikAdvancedSelectDropdown = <T,>({
	options,
	isFetching,
	makeSearch,
	setPage,
	placeholder = '',
	classNames = {},
	closeMenuOnSelect = true,
	label = '',
	className = '',
	isClearable = false,
	isSearchable = true,
	name = '',
	disabled = false,
	isMulti,
	required = true,
	totalPages,
	page = 0,
	search,
	setSearch,
	...rest
}: IFormikAdvancedSelectDropdownProps) => {
	//Functions Section
	const totalDropdownPagesRef = useRef(0)
	const currentPageRef = useRef(1)
	const isDataFetchingRef = useRef(true)

	const makeSearchCommon = createSearchHandler(
		setSearch as React.Dispatch<React.SetStateAction<string>>,
		setPage,
	)

	const onScrollDown = () => {
		const totalDropdownPages = totalDropdownPagesRef.current

		const currentPage = currentPageRef.current
		const isDataFetchingRefValue = isDataFetchingRef.current
		if (totalDropdownPages > currentPage && !isDataFetchingRefValue) {
			const currentPageValue = currentPage + 1
			currentPageRef.current = currentPageValue
			setPage(currentPageValue)
		}
	}

	const filteredSearch = (inputValue: string) => {
		makeSearch ? makeSearch(inputValue) : makeSearchCommon(inputValue)
	}

	const handleInputChange = (inputValue: string, { action }: InputActionMeta) => {
		if (action === 'input-blur' && search) {
			filteredSearch(inputValue)
			currentPageRef.current = 1
		} else if (action !== 'input-blur' && action !== 'menu-close') {
			filteredSearch(inputValue)
		}
	}
	// useEffect Section
	useEffect(() => {
		if (totalPages) {
			totalDropdownPagesRef.current = totalPages
		}
	}, [totalPages])

	useEffect(() => {
		if (page !== currentPageRef.current) {
			currentPageRef.current = page
		}
	}, [page])

	useEffect(() => {
		isDataFetchingRef.current = isFetching
	}, [isFetching])

	useEffect(() => {
		return () => {
			currentPageRef.current = 1
			totalDropdownPagesRef.current = 0
			isDataFetchingRef.current = true
		}
	}, [])

	return (
		<>
			<FormikAdvanceSelect
				isLoading={isFetching}
				label={label}
				isMulti={isMulti}
				placeholder={placeholder}
				onInputChange={handleInputChange}
				options={options}
				onCustomMenuScrollToBottom={onScrollDown}
				name={name}
				closeMenuOnSelect={closeMenuOnSelect}
				className={className}
				isClearable={isClearable}
				isSearchable={isSearchable}
				classNames={classNames}
				isDisabled={disabled}
				required={required}
				{...rest}
			/>
		</>
	)
}

export default FormikAdvancedSelectDropdown
