import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { FormikHelpers } from 'formik'
import axios, { AxiosResponse } from 'axios'
import { useUrlQuery } from 'hooks/url'
import { ENDPOINTS } from 'const/endpoints'
import { errorNotify, successNotify } from 'utils/toster'
import { scrollToError } from 'utils/common'
import { commonMessages } from 'utils/messages'
import {
	createReferral,
	deleteUploadFile,
	fileUploadReferral,
	getPartnerForReferrals,
	getPartnerSpeciality,
	getPatientsForReferrals,
	getProvidersDoctorSpeciality as getProvidersDoctorSpecialty,
	getReferralConditions,
	getSingleReferral,
	updateReferral,
} from '../Referral.service'
import { ReferralInitalState, ReferralPayload } from './Referral.types'
import { DROPDOWN_LIMIT } from 'const/common'
import { getClientDetails } from 'hooks/common'

const ERRORS = {
	phone: ['This number already exist in database', 'This number already exist in firebase'],
}
//* Formik useInitial hook
export function useInitial() {
	/**
	 * *Hooks and variable Section
	 */
	const alreadyMounted = useRef(false)
	const [param] = useUrlQuery<any>()
	const params = useParams<{ id: string }>()
	const id = params.id
	const { data, isFetched, isFetching } = useReferralEdit({
		id: param.referralId,
		clientId: getClientDetails()?.clientId,
		suspense: !alreadyMounted.current, //TO USE SUSPENSE ONCE ON MOUNT
	})
	/**
	 * *useEffects Section
	 * @param useEffectName
	 */
	useEffect(() => {
		alreadyMounted.current = true
	}, [isFetched, isFetching])
	/**
	 * *useMemo Section
	 * @param useEffectName
	 */
	return useMemo(() => {
		const partnerTimeFrame = new ReferralInitalState(param.referralId ? { ...data?.data } : {})
		return partnerTimeFrame
	}, [data?.data, isFetching, id, open])
}
/**
 * *Formik useSubmitHandler hook
 */
export function useSubmitHandler(setAddReferrals: any) {
	/**
	 * *Custom hooks section.
	 */
	const [params, setParams] = useUrlQuery<any>()
	/**
	 * * 1. Using useCreateReferral() hook to create a mutateAsync function for creating a new referrals .
	 * * 2. Using useUpdateReferral() hook to create a mutateAsync function for updating an existing referrals, with the id specified in the params.
	 */
	const { mutateAsync: create } = useCreateReferral({ clientId: getClientDetails()?.clientId })
	const { mutateAsync: update } = useUpdateReferral({
		id: params?.referralId,
		clientId: getClientDetails()?.clientId,
	})
	/**
	 * *Variable and hooks section.
	 */
	const id = params?.referralId
	const navigate = useNavigate()
	const client = useQueryClient()
	/**
	 * *useCallback section
	 */
	return useCallback(
		async (form: ReferralInitalState, formikHelpers: FormikHelpers<ReferralInitalState>) => {
			formikHelpers.setSubmitting(true)
			try {
				if (!id) {
					await create(new ReferralPayload(form))
				} else {
					await update(new ReferralPayload(form))
				}
				successNotify(id ? commonMessages.updateMessage : commonMessages.createMessage)
				formikHelpers.resetForm()
				formikHelpers.setSubmitting(false)
				setAddReferrals(false)
				setParams((old) => {
					return {
						...old,
						referralId: undefined,
						addReferral: undefined,
					}
				})
				client.invalidateQueries([ENDPOINTS.REFERRAL_LIST])
			} catch (err: any) {
				const errorMsg = err?.response?.data?.message
				if (err?.response?.data && err?.response?.data?.message) {
					Object.entries(ERRORS).forEach(([key, value]: [string, string[]]) => {
						if (value?.includes(err.response.data.message)) {
							formikHelpers.setFieldError(key, err.response.data.message)
						}
					})
					errorNotify(errorMsg)
					// Somewhere else, even another file
				} else {
					formikHelpers.setErrors(err?.response?.data?.address) //TODO - ADD ERRORS TRANSFORMATION TO FORM FORMAT
					errorNotify(commonMessages.errorHandlingMessage)
				}
				scrollToError()
			}
		},
		[client, create, navigate, id],
	)
}
/**
 * * Using React Hook function to create a function to retrieving a list of patients from a specified source and returning it for referral..
 */

export function usePatientsForReferrals({ id }: { id: string }) {
	return useQuery(
		[ENDPOINTS.ALL_MEMBER_FOR_REFERRAL, id],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getPatientsForReferrals(source, id)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!id,
		},
	)
}
// useReferralPartner
export function useReferralPartner({ id, memberId }: { id: string; memberId: string }) {
	return useQuery(
		[ENDPOINTS.PARTNER_CLIENTS, id, memberId],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getPartnerForReferrals(source, id, {
				member: memberId,
			})
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!id && !!memberId,
		},
	)
}
/**
 * * Using React Hook function to create a Query  function to single referral from a list of referrals. It takes in the list of referrals as an argument and then returns the first referral from the list.
 */
interface IReferralProps extends Partial<UseQueryOptions<AxiosResponse<any>>>, Partial<any> {
	id?: string
	clientId?: string
}
export function useReferralEdit({ id, clientId, ...rest }: IReferralProps) {
	return useQuery<AxiosResponse<any>, any, AxiosResponse<any>>(
		[ENDPOINTS.REFERRAL, id, clientId],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getSingleReferral(id, clientId, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!id,
			cacheTime: 0,
			...rest,
		},
	)
}
/**
 * * Using React Hook function to create a Query  function retrieving the partner speciality of a given source. This could be information such as the type of products or services the partner specialities.
 */
export function usePartnerSpeciality({ clientId }: { clientId: string }) {
	return useQuery(
		[ENDPOINTS.PATIENT_SPECILIZATION, clientId],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getPartnerSpeciality(clientId, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	)
}
/**
 * * Using React Hook function to create a Query  function  to get a list of referral conditions for a given ID, source, and search query. The search query (if provided) is used to filter down the results.
 */
export function useReferralCondition({
	search,
	page = 1,
}: // limit,
{
	id: string
	search?: string
	page: number
	// limit?: number
}) {
	return useQuery<any>(
		[ENDPOINTS.PROGRAM_CONDITION, search, page],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getReferralConditions(source, {
				search,
				page,
				// limit: 100,
			})
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	)
}
/**
 * * Using React Hook function to create a Query  function to get a list of providers who specialize in the given id, from the given source, with the given providerSearch as a search term.
 */
export function useProvidersDoctorSpecialty({
	id,
	partner,
	patient,
	page = 1,
	search = '',
	limit,
}: {
	id: string
	partner: string
	patient: number | string | any
	page?: number
	search?: string
	limit?: number
}) {
	return useQuery<any>(
		[ENDPOINTS.PROVIDER_UNDER_SPECIALIZATION, search, id, partner, patient, page, limit],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getProvidersDoctorSpecialty(id, source, {
				search,
				limit,
				partner,
				member: patient,
				page,
			})
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!id,
		},
	)
}

/**
 * Referral file-upload
 */

export function useFileUploadReferrals({ clientId, ...rest }: any = {}) {
	return useMutation<AxiosResponse<any>, any, Partial<any>>(
		async (data) => fileUploadReferral(clientId, data),
		{
			...rest,
		},
	)
}

/**
 * Referral delete file
 */

export function useFileDeleteReferrals({ clientId, ...rest }: any = {}) {
	return useMutation<AxiosResponse<any>, any, Partial<any>>(
		async (data) => deleteUploadFile(clientId, data),
		{
			...rest,
		},
	)
}

/**
 * * Using the React Hooks useMutation to create a mutation function for creating a create Referral.
 */
export function useCreateReferral({ clientId, ...rest }: any = {}) {
	return useMutation<AxiosResponse<any>, any, Partial<any>>(
		async (data) => await createReferral(clientId, data),

		{
			...rest,
		},
	)
}
/**
 * * Using the React Hook useMutation to create a mutation function for updating a Referral.
 */
export function useUpdateReferral({ id, clientId, ...rest }: any) {
	return useMutation<AxiosResponse<any>, any, Partial<any>>(
		async (data) => await updateReferral(id, clientId, data),
		{
			...rest,
		},
	)
}
