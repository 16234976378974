import _ from 'lodash'
import { CancelTokenSource } from 'axios'
import axios from 'services/axios'
import { ENDPOINTS } from 'const/endpoints'
import { IPagination } from 'types/entities'
import { commonMessages } from 'utils/messages'
import { IConditionParams, IFullConditions } from './MemberCondition.interface'
import { ConditionPayload } from './MemberCondition.types'
import { onCreateEndpointsWithClientid } from 'utils/common'

export function getMemberConditions(
	id: string | undefined,
	source?: CancelTokenSource,
	clientId?: string | undefined,
	params?: Partial<IConditionParams>,
) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get<IPagination<Partial<IFullConditions>>>(
		ENDPOINTS.PATIENT_CONDITIONS + '/' + clientId + '/' + id,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function getMemberCondition(id: string | undefined, source?: CancelTokenSource) {
	const promise = axios.get<IFullConditions>(ENDPOINTS.PATIENT_CONDITIONS + '/' + id)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function createCondition(
	id: string | any,
	clientId: string | undefined,
	data: Partial<ConditionPayload>,
	source?: CancelTokenSource,
) {
	if (!data?.assignee) {
		delete data.assignee
	}
	if (!data?.description) {
		delete data.description
	}
	const promise = axios.post<ConditionPayload>(ENDPOINTS.PATIENT_CONDITION + '/' + clientId, {
		...data,
		patient: id,
	})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function updateCondition(
	id: string | undefined,
	conditionId: string | undefined,
	clientId: string | undefined,
	data: Partial<ConditionPayload>,
	source?: CancelTokenSource,
) {
	if (!data?.assignee) {
		delete data.assignee
	}
	const promise = axios.patch<ConditionPayload>(
		ENDPOINTS.PATIENT_CONDITION + '/' + clientId + '/' + conditionId,
		{
			...data,
			patient: id,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

// Member Conditions

export function getMemberConditionsDropdown(
	id: string,
	clientId?: string | undefined,
	source?: CancelTokenSource,
) {
	const promise = axios.get(
		onCreateEndpointsWithClientid(ENDPOINTS.PATIENT_PROGRAM_CONDITIONS) + '/' + id,
	)
	//@ts-ignore
	// Add cancel method to the promise
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}

export function getConditionsProvider(
	id: string,
	params: Partial<{
		page: number
		limit: number
		search: string
	}>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get(
		onCreateEndpointsWithClientid(ENDPOINTS.CONDITION_PARTNER_ASSIGNEE) + '/' + id,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	// Add cancel method to the promise
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}

	return promise
}
// Member Assignees

export function getMemberAssigneeDropdown(
	clientId: string | undefined,
	params: { partner: string; page: string | number; search: any },
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get(ENDPOINTS.PROVIDERS_DOCTORS + '/' + clientId, {
		params: filteredParams,
	})
	//@ts-ignore
	// Add cancel method to the promise
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function getCondition(
	id: string | undefined,
	clientId: string | undefined,
	source?: CancelTokenSource,
) {
	const promise = axios.get<IFullConditions>(
		ENDPOINTS.PATIENT_CONDITION + '/' + clientId + '/' + id,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}
