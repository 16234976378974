import _ from 'lodash'
import axios from 'services/axios'
import { CancelTokenSource } from 'axios'
import { ENDPOINTS } from 'const/endpoints'
import { IAddress, IHumanName, IPagination } from 'types/entities'
import { commonMessages } from 'utils/messages'
import { GroupPayload } from './Group/Group.types'
import {
	IFullGroupParams,
	IGroupClientsParams,
	IGroupParams,
	IGroupProvidersParams,
} from './Groups.interface'
import { IClient, IClientRepresentative } from 'modules/Clients/Clients.interface'
import { onCreateEndpointsWithClientid } from 'utils/common'

/**
 * Create Groups
 */
export function createGroup(data: Partial<GroupPayload>, source?: CancelTokenSource) {
	const promise = axios.post<GroupPayload>(onCreateEndpointsWithClientid(ENDPOINTS.GROUPS), data)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * Update Groups
 */
export function updateGroup(
	id: string | undefined,
	data: Partial<GroupPayload>,
	source?: CancelTokenSource,
) {
	const promise = axios.patch<GroupPayload>(
		onCreateEndpointsWithClientid(ENDPOINTS.GROUPS) + '/' + id,
		data,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * Groups Status
 */

export function statusGroupsToggle(id: string | undefined) {
	const promise = axios.patch(onCreateEndpointsWithClientid(ENDPOINTS.GROUPS_STATUS) + '/' + id)
	//@ts-ignore

	return promise
}

/**
 * Get Groups Listing
 */
export function getGroupsListing(params: Partial<IGroupParams>, source?: CancelTokenSource) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get<IPagination<Partial<IFullGroupParams>>>(
		onCreateEndpointsWithClientid(ENDPOINTS.GROUPS_ALL),
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * Get Provider Groups Listing
 */

export function getProviderGroupsList(
	source?: CancelTokenSource,
	params?: Partial<{
		page: number
		limit: number
		client: string
		partner: string
		search: string
	}>,
) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get(onCreateEndpointsWithClientid(ENDPOINTS.PROVIDERS_GROUPS), {
		params: filteredParams,
	})
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * Get Groups by id
 */
export function getGroup(id: string | undefined, source?: CancelTokenSource) {
	const promise = axios.get<GroupPayload>(
		onCreateEndpointsWithClientid(ENDPOINTS.GROUPS) + '/' + id,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

// Delete Groups
export function deleteGroup(id: string | undefined, source?: CancelTokenSource) {
	const promise = axios.delete<GroupPayload>(
		onCreateEndpointsWithClientid(ENDPOINTS.GROUPS) + '/' + id,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

// Delete Groups Provider
export function deleteGroupProvider(id: string | undefined, source?: CancelTokenSource) {
	const promise = axios.delete<GroupPayload>(
		onCreateEndpointsWithClientid(ENDPOINTS.GROUPS_PROVIDER) + '/' + id,
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

/**
 * Get Groups Providers Listing by id
 */
export function getGroupsProvidersListing(
	groupId: string | undefined,
	params: Partial<IGroupProvidersParams & IGroupParams>,
	source?: CancelTokenSource,
) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get<IPagination<Partial<IGroupProvidersParams>>>(
		onCreateEndpointsWithClientid(ENDPOINTS.GROUPS_PROVIDERS) + '/' + groupId,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	return promise
}

export function getGroupClients(params: Partial<IGroupClientsParams>, source?: CancelTokenSource) {
	const filteredParams = _.pickBy(params, _.identity)
	const promise = axios.get<IPagination<IClient<IClientRepresentative<IHumanName>, IAddress>>, any>(
		ENDPOINTS.CLIENTS_ALL,
		{
			params: filteredParams,
		},
	)
	//@ts-ignore
	promise.cancel = () => {
		source?.cancel(commonMessages.queryCancelledMessage)
	}
	promise.then(() => {
		//
	})
	return promise
}
