import { IClient } from 'modules/Clients/Clients.interface'
import { SelectOption } from 'types/entities'
import { ISelectOption } from 'types/forms'

export class GroupInitialState {
	id?: string
	groupName: string | undefined
	description: string | undefined
	client: ISelectOption | null = null
	groupProviders: any
	partner: ISelectOption | null = null
	active: 'isActive' | 'isInactive' = 'isActive'

	constructor({ ...data }: Partial<any> = {}) {
		this.addGroupName(data?.groupName)
		this.addDescription(data?.description)
		this.addClient(data?.client)
		this.addGroupProviders(data?.groupProviders)
		this.addIsActive(data?.active)
		this.addPartner(data?.partner)
	}

	addPartner(partner: string | undefined | any) {
		if (partner) {
			this.partner = new SelectOption(partner?.id, partner?.companyName)
		}
	}

	// addPartner(data: any) {
	// 	if (data) {
	// 		const newArray = data.map((obj: any) => ({
	// 			label: obj.condition.condition,
	// 			value: obj.condition.id,
	// 		}))
	// 		this.partner = newArray
	// 	}
	// }

	addGroupName(groupName: string | undefined) {
		groupName && (this.groupName = groupName)
	}

	addDescription(description: string | undefined) {
		if (description) {
			this.description = description
		}
	}
	addClient(client: IClient | undefined) {
		if (client) {
			this.client = new SelectOption(client?.id, client?.title)
		}
	}
	addGroupProviders(data: any) {
		this.groupProviders = data?.map((item: any) => {
			return {
				label: item?.provider?.humanName.text,
				value: item?.provider?.id,
			}
		})
	}

	addIsActive(isActive?: any) {
		if (typeof isActive === 'boolean') {
			if (isActive) {
				this.active = 'isActive'
			}
			if (!isActive) {
				this.active = 'isInactive'
			}
		}
	}
}

export class GroupPayload {
	id?: string
	client: string | null = null
	groupProviders: any
	partner: any
	description: string | undefined
	active = true
	groupName: string | undefined

	constructor({ ...data }: Partial<GroupInitialState> = {}) {
		this.addGroupName(data?.groupName)
		this.addDescription(data?.description)
		this.addClient(data?.client)
		this.addGroupProviders(data?.groupProviders)
		this.addIsActive(data?.active)
		this.addPartner(data?.partner)
	}

	// addPartner(partner: string | undefined | any) {
	// 	if (partner) {
	// 		this.partner = new SelectOption(partner?.id, partner?.companyName)
	// 	}
	// }
	// addPartner(data: any) {
	// 	// partnerObj && (this.partner = partnerObj?.id)
	// 	// if (data?.length) {
	// 	// 	this.partner = data?.map((obj: any) => {
	// 	// 		return {
	// 	// 			provider: obj.value,
	// 	// 		}
	// 	// 	})
	// 	// }
	// 	const multiPartners = data?.map((obj: any) => {
	// 		return obj?.value
	// 	})
	// 	this.partner = multiPartners
	// }

	addPartner(partner: string | undefined | any) {
		if (partner) {
			this.partner = partner?.id
		}
	}
	// addPartner(data: any) {
	// 	// partnerObj && (this.partner = partnerObj?.id)
	// 	// if (data?.length) {
	// 	// 	this.partner = data?.map((obj: any) => {
	// 	// 		return {
	// 	// 			provider: obj.value,
	// 	// 		}
	// 	// 	})
	// 	// }
	// 	const multiPartners = data?.map((obj: any) => {
	// 		return obj?.value
	// 	})
	// 	this.partner = multiPartners
	// }

	addGroupName(groupName: string | undefined) {
		groupName && (this.groupName = groupName)
	}

	addDescription(description: string | undefined) {
		if (description) {
			this.description = description
		}
	}
	addClient(client: ISelectOption | undefined | null) {
		if (client) {
			this.client = client?.value
		}
	}
	addGroupProviders(data: any) {
		if (data?.length) {
			this.groupProviders = data?.map((obj: any) => {
				return {
					provider: obj.value,
				}
			})
		}
	}
	addIsActive(active: any) {
		if (active === 'isActive') {
			this.active = true
		}
		if (active === 'isInactive') {
			this.active = false
		}
	}
}
