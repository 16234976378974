import { ReactNode } from 'react'

import { InputProps } from '../Input'
import classnames from 'classnames'
import { Label } from 'components/Label'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'
import { CustomCheckbox } from 'components/CustomCheckbox'

interface IProps extends InputProps {
	label?: string | ReactNode
	error?: any
	name?: string
	children?: React.ReactNode
	className?: string
	classNames?: {
		label?: string
		input?: string
		content?: string
		children?: string
	}
	isLoader?: any
	isChecked?: any
	disabled?: boolean
	onClick?: () => void
	isDefault?: boolean
}

export default function CheckboxField({
	classNames,
	className,
	label = '',
	error,
	children,
	isLoader,
	name,
	checked,
	isChecked,
	onClick,
	disabled = false,
	isDefault = false,
	...rest
}: IProps) {
	const containerClasses = classnames('relative', className, {
		'mb-4': !error,
	})
	const labelClasses = classnames('', { disabled: disabled }, classNames?.label)
	const classes = classnames('select', className)

	return (
		<div className={classnames(classes, isDefault ? 'flex-col items-start flex gap-0' : 'flex')}>
			<div className={containerClasses}>
				<Label name={label + ''} className={labelClasses} />
				<div className={`flex relative gap-2 items-center	 ${classNames?.content}`}>
					<CustomCheckbox
						{...rest}
						name={name}
						checked={isChecked}
						disabled={disabled}
						className={(classNames?.input || '') + ' shrink-0'}
						onClick={onClick}
					/>
					<div className={classNames?.children}>{children}</div>
				</div>
				{error && <ErrorMessage>{error}</ErrorMessage>}
			</div>
		</div>
	)
}
