export enum SESSION_FREQUENCY {
	ONE = '1',
	TWO = '2',
	THREE = '3',
	FOUR = '4',
	FIVE = '5',
	SIX = '6',
	SEVEN = '7',
	EIGHT = '8',
	NINE = '9',
	TEN = '10',
}

export enum SESSION_PERIOD {
	DAILY = 'Daily',
	WEEKLY = 'Weekly',
	MONTHLY = 'Monthly',
}

export enum SESSION_TYPE {
	SINGLE_SESSION = 'single',
	MULTIPLE_SESSION = 'multiple',
}

export enum TO_BE_COMPLETED {
	SEVEN_DAYS = '7 Days',
	FIFTEEN_DAYS = '15 Days',
	ONE_MONTH = '1 Month',
	TWO_MONTH = '2 Months',
	THREE_MONTH = '3 Months',
	FOUR_MONTHS = '4 months',
	FIVE_MONTHS = '5 months',
	SIX_MONTHS = '6 months',
	TWELVE_MONTHS = '12 months',
}
export enum ANSWERED_BY {
	MEMBER = 'member',
	PROVIDER = 'provider',
}

export enum FIELD_NAMES {
	TYPE = 'type',
	TYPEID = 'typeId',
	SESSION = 'session',
	TITLE = 'title',
	TO_BE_COMPLETED = 'toBeCompleted',
	TO_BE_COMPLETEDID = 'toBeCompletedId',

	DURATION = 'duration',
	HOURS = 'hours',
	MINUTES = 'minutes',
	DESCRIPTION = 'description',
	IS_SINGLE = 'isSingle',
	DOCUMENT = 'document',
	FREQUENCY = 'frequency',
	PERIOD = 'period',
	PERIOD_ID = 'periodId',

	TO_BE_ANSWERED = 'questionnaireAnsweredBy',
	TO_BE_ANSWERED_BY_ID = 'questionnaireAnsweredById',

	TOTAL_SESSION = 'totalSession',
	SESSION_CODE = 'sessionCode',
}

export const SESSION_TYPE_OPTION: {
	label: string
	value: SESSION_TYPE
}[] = [
	{ label: 'Single Session', value: SESSION_TYPE.SINGLE_SESSION },
	{ label: 'Multiple Session', value: SESSION_TYPE.MULTIPLE_SESSION },
]

export const SESSION_PERIOD_OPTIONS: {
	label: string
	value: SESSION_PERIOD
}[] = [
	{ label: 'Daily', value: SESSION_PERIOD.DAILY },
	{ label: 'Weekly', value: SESSION_PERIOD.WEEKLY },
	{ label: 'Monthly', value: SESSION_PERIOD.MONTHLY },
]

export const SESSION_FREQUENCY_OPTIONS: {
	label: string
	value: SESSION_FREQUENCY
}[] = [
	{ label: '1', value: SESSION_FREQUENCY.ONE },
	{ label: '2', value: SESSION_FREQUENCY.TWO },
	{ label: '3', value: SESSION_FREQUENCY.THREE },
	{ label: '4', value: SESSION_FREQUENCY.FOUR },
	{ label: '5', value: SESSION_FREQUENCY.FIVE },
	{ label: '6', value: SESSION_FREQUENCY.SIX },
	{ label: '7', value: SESSION_FREQUENCY.SEVEN },
	{ label: '8', value: SESSION_FREQUENCY.EIGHT },
	{ label: '9', value: SESSION_FREQUENCY.NINE },
	{ label: '10', value: SESSION_FREQUENCY.TEN },
]

export const TO_BE_COMPLETED_OPTIONS: {
	label: string
	value: TO_BE_COMPLETED
}[] = [
	{ label: '7 Days', value: TO_BE_COMPLETED.SEVEN_DAYS },
	{ label: '15 Days', value: TO_BE_COMPLETED.FIFTEEN_DAYS },
	{ label: '1 Month', value: TO_BE_COMPLETED.ONE_MONTH },
	{ label: '2 Months', value: TO_BE_COMPLETED.TWO_MONTH },
	{ label: '3 Months', value: TO_BE_COMPLETED.THREE_MONTH },
	{ label: '4 Months', value: TO_BE_COMPLETED.FOUR_MONTHS },
	{ label: '5 Months', value: TO_BE_COMPLETED.FIVE_MONTHS },
	{ label: '6 Months', value: TO_BE_COMPLETED.SIX_MONTHS },
	{ label: '12 Months', value: TO_BE_COMPLETED.TWELVE_MONTHS },
]

export const TO_BE_ANSWERED_OPTIONS: {
	label: string
	value: ANSWERED_BY
}[] = [
	{ label: 'Member', value: ANSWERED_BY.MEMBER },
	{ label: 'Provider', value: ANSWERED_BY.PROVIDER },
]

export enum SessionDurationInitialValue {
	hours = '',
	minutes = '',
}

export const getHoursOptions = () => {
	const hourOptions = []
	for (let i = 0; i <= 24; i++) {
		hourOptions.push({ value: String(i).padStart(2, '0'), label: String(i).padStart(2, '0') })
	}
	return hourOptions
}

export const getMinutesOptions = () => {
	const minuteOptions = []
	for (let i = 0; i <= 60; i++) {
		minuteOptions.push({
			value: String(i).padStart(2, '0'),
			label: String(i).padStart(2, '0'),
			name: 'minute',
		})
	}
	return minuteOptions
}
