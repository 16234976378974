import { useEffect, useMemo, useRef, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from 'firebase/auth'
import axios from 'services/axios'
import { useGetAllGlobalCodes, useMe } from './react-query'
import { LOCAL_STORAGE } from 'const/common'
import { getLocalStorageDetails, onParseJSON, onVerifyArray } from 'utils/common'
import { IPermissionDetails } from '../types/permission.types'
import { getClientDetails } from './common'
import { IGlobalCodes } from 'types/entities'

export function useAuth() {
	const auth = useMemo(() => {
		return getAuth()
	}, [])

	const [user, loading, error] = useAuthState(auth)
	axios.interceptors.request.use(
		async function (request) {
			if (user) {
				try {
					const token = localStorage.getItem('token')
					// const token = await user.getIdToken(false) // Force refresh is false
					// localStorage.setItem('token', token)
					request.headers = {
						...request.headers,
						Authorization: 'Bearer ' + token,
					}
				} catch (error) {}
			}

			return request
		},
		function (error) {
			// Any status codes that falls outside the range of 2xx cause this function to trigger
			// Do something with response error
			return Promise.reject(error)
		},
	)

	return { user, loading, error, auth }
}
const getServiceName = () => {
	const serviceName = getLocalStorageDetails(LOCAL_STORAGE?.SERVICE_NAME)
	return serviceName
}
const getPermissionDetail = () => {
	const permissionDetail = getLocalStorageDetails(LOCAL_STORAGE?.PERMISSION_DETAIL)
	// permissionDetail = permissionDetail ? JSON.parse(permissionDetail) : []
	const permissionDetailParsed: IPermissionDetails[] | [] = permissionDetail
		? onParseJSON(permissionDetail)
		: []
	return permissionDetailParsed
}
const getServicePermissionDetails = () => {
	const permissionData = getPermissionDetail()
	const servicePermissionDetails =
		// Array.isArray(permissionData) &&
		onVerifyArray(permissionData) &&
		permissionData?.find(
			(permissionObj: any) => permissionObj?.clientService?.clientServiceName === getServiceName(),
		)
	// servicePermissionDetails = servicePermissionDetails ? servicePermissionDetails : []
	const servicePermissionFindDetails: IPermissionDetails | {} = servicePermissionDetails
		? servicePermissionDetails
		: {}
	return servicePermissionFindDetails
}

export function useAuthContext() {
	//here add auth context logic
	const [accessType, setAccessType] = useState<null | 'public' | 'private' | 'common'>(null)
	const [globalCodes, setGlobalCodes] = useState<[] | IGlobalCodes[]>([])
	const { user, loading } = useAuth() // get user session on mount
	const alreadyMount = useRef(false)
	const { data, isFetching } = useMe({
		suspense: !alreadyMount,
		enabled: !!user,
	})
	const { data: getAlGlobalData, isFetching: isGlobalCodesFetching } = useGetAllGlobalCodes({
		clientId: getClientDetails()?.clientId,
		isEnabled: !!user && !!getClientDetails()?.clientId,
	})

	useEffect(() => {
		alreadyMount.current = true
	}, [])

	useEffect(() => {
		if (!isGlobalCodesFetching && getAlGlobalData?.data?.length) {
			setGlobalCodes(getAlGlobalData?.data)
		}
	}, [isGlobalCodesFetching, getAlGlobalData?.data])

	const context = useMemo(() => {
		return {
			isAuth: !!user,
			accessType,
			setAccessType: setAccessType,
			user,
			roles: [data?.data?.role], //TODO
			profile: data?.data,
			// serviceName: serviceName,
			// permission: permissionDetail,
			// servicePermission: servicePermissionDetails,
			serviceName: getServiceName(),
			permission: getPermissionDetail(),
			servicePermission: getServicePermissionDetails(),
			globalCodes,
		}
		// }, [accessType, data?.data, user, servicePermissionDetails, permissionDetail])
	}, [
		accessType,
		data?.data,
		user,
		getClientDetails()?.clientId,
		getLocalStorageDetails(LOCAL_STORAGE?.PERMISSION_DETAIL),
		getLocalStorageDetails(LOCAL_STORAGE?.SERVICE_NAME),
	])
	return { context, isLoading: loading || isFetching }
}
