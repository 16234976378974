import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom'
import {
	useMutation,
	UseMutationOptions,
	useQuery,
	useQueryClient,
	UseQueryOptions,
} from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { useUrlQuery } from 'hooks/url'
import { debounceHandler, useMyProfile } from 'hooks/common'
import { APP_ROUTES } from 'const/router'
import { GroupInitialState, GroupPayload } from './Group.types'
import {
	createGroup,
	getGroup,
	getGroupClients,
	getProviderGroupsList,
	statusGroupsToggle,
	updateGroup,
} from '../Groups.service'
import { FormikHelpers } from 'formik'
import { errorNotify, successNotify } from 'utils/toster'
import { commonMessages } from 'utils/messages'
import { ENDPOINTS } from 'const/endpoints'
import { scrollToError } from 'utils/common'
import { IAddress, IAxiosResponseWithPagination, IHumanName } from 'types/entities'
import { IGroupClientsParams } from '../Groups.interface'
import { IClient, IClientRepresentative } from 'modules/Clients/Clients.interface'
import { DROPDOWN_LIMIT } from 'const/common'

export function useInitial() {
	const alreadyMounted = useRef(false)
	const location = useLocation()
	const profile = useMyProfile()

	const [params] = useUrlQuery<{ id: string }>()
	let id = params.id
	if (matchPath(APP_ROUTES.ME, location.pathname)) {
		id = profile.id
	}
	const { data, isFetched, isFetching } = useGroups({
		id,
		suspense: !alreadyMounted.current, //TO USE SUSPENSE ONCE ON MOUNT
	})

	useEffect(() => {
		alreadyMounted.current = true
	}, [isFetched])

	return useMemo(() => {
		return new GroupInitialState(data?.data)
	}, [data?.data, isFetching])
}

export function useSubmitHandler(props: any) {
	/**
	 * *Variable & hooks Section
	 */
	const navigate = useNavigate()
	const [params, setUrlQuery] = useUrlQuery<{ id: string | undefined }>()
	const location = useLocation()
	const profile = useMyProfile()
	const client = useQueryClient()
	let id = params.id
	if (matchPath(APP_ROUTES.ME, location.pathname)) {
		id = profile.id
	}
	/**
	 * * 1. Using useCreateGroup() hook to create a mutateAsync function for creating a new Group.
	 * * 2. Using useUpdateGroup() hook to create a mutateAsync function for updating an existing Group, with the id specified in the params.
	 */
	const { mutateAsync: create } = useCreateGroup()
	const { mutateAsync: update } = useUpdateGroup({ id })
	/**
	 * * useCallback Section
	 */
	return useCallback(
		async (form: GroupInitialState, formikHelpers: FormikHelpers<GroupInitialState>) => {
			formikHelpers.setSubmitting(true)
			try {
				if (!id) {
					await create(new GroupPayload(form))
				} else {
					await update(new GroupPayload(form))
				}
				successNotify(id ? commonMessages.updateMessage : commonMessages.createMessage)
				formikHelpers.resetForm()
				props?.setCreateNewGroup(false)
				setUrlQuery((prev: any) => {
					return {
						...prev,
						id: '',
						groupId: '',
					}
				})
				formikHelpers.setSubmitting(false)
				client.invalidateQueries([ENDPOINTS.GROUPS_ALL])
			} catch (err: any) {
				formikHelpers.setErrors(err.response.data) //TODO - ADD ERRORS TRANSFORMATION TO FORM FORMAT
				scrollToError()
				if (err?.response?.data?.message) {
					errorNotify(err?.response?.data?.message)
				} else {
					errorNotify(commonMessages.errorHandlingMessage)
				}
			}
		},
		[client, create, id, navigate, update],
	)
}

/**
 * * Using the React Hooks useMutation to create a mutation function for creating a new Group.
 */
type ICreateGroupProps = Partial<
	UseMutationOptions<AxiosResponse<GroupPayload>, any, Partial<GroupPayload>>
>

export function useCreateGroup({ ...rest }: ICreateGroupProps = {}) {
	return useMutation<AxiosResponse<GroupPayload>, any, Partial<GroupPayload>>(createGroup, {
		...rest,
	})
}

/**
 * * Using the React Hook useMutation to create a mutation function for updating a Group.
 */
interface IUpdateGroupProps
	extends Partial<UseMutationOptions<AxiosResponse<GroupPayload>, any, Partial<GroupPayload>>> {
	id?: string
}

export function useUpdateGroup({ id, ...rest }: IUpdateGroupProps) {
	return useMutation<AxiosResponse<GroupPayload>, any, Partial<GroupPayload>>(
		async (data) => await updateGroup(id, data),
		{
			...rest,
		},
	)
}

/**
 * * Using React Hook useMutation to create a mutation to update the status of a Group.
 */
interface IUpdateStatusToggle {
	active?: true | false
}
interface IUpdateStatusToggleProps
	extends Partial<UseMutationOptions<AxiosResponse<GroupPayload>, any, IUpdateStatusToggle>>,
		IUpdateStatusToggle {}

export function useGroupsUpdateToggle({ ...rest }: IUpdateStatusToggleProps = {}) {
	//@ts-ignore
	return useMutation<AxiosResponse<GroupPayload>, any, any>(
		async (id: string) => await statusGroupsToggle(id),
		{
			...rest,
		},
	)
}

/**
 * * Using React Hook function to retrieving the groups associated with a given ID and source.
 */
interface ICptQueryProps
	extends Partial<UseQueryOptions<AxiosResponse<GroupPayload>>>,
		Partial<GroupPayload> {
	id?: string
}

export function useGroups({ id, ...rest }: ICptQueryProps) {
	return useQuery<AxiosResponse<GroupPayload>, any, AxiosResponse<GroupPayload>>(
		[ENDPOINTS.GROUPS, id],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()
			return getGroup(id, source)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!id,
			suspense: true,
			...rest,
		},
	)
}

/**
 * * Using the React Hooks useQuery to create a  function  method will then return a list of providers that match the source and options provided..
 */
export function useProviderGroupsList({
	limit = DROPDOWN_LIMIT.LIMIT,
	clientId,
	partner,
	page = 1,
	search = '',
	isEnabled = true,
}: Partial<{
	page: number
	limit: number
	partner: string
	clientId: string
	search: string
	isEnabled: boolean
}>) {
	return useQuery(
		[ENDPOINTS.PROVIDERS_GROUPS, clientId, partner, page, limit, search],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getProviderGroupsList(source, {
				client: clientId,
				partner,
				page,
				limit,
				search,
			})
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: !!clientId && isEnabled,
		},
	)
}

export function useAllClientsProviderDynamicOptions({
	limit = DROPDOWN_LIMIT.LIMIT,
	isEnabled = true,
	page,
	sort,
	setPage,
}: {
	limit?: number
	isEnabled?: boolean
	page?: number
	sort?: string
	setPage?: any
}) {
	const [search, setSearch] = useState('')
	const [clientOptions, setClientOptions] = useState<any>([])
	const [clientSearchOptions, setClientSearchOptions] = useState<any>([])
	const { data, isLoading, isFetching, refetch } = useAllClients({
		isEnabled: isEnabled,
		search: search,
		suspense: false,
		limit: limit,
		page: page,
		sort: sort,
	})

	const handleSearch = (data: string | undefined) => {
		setSearch(data || '')
		setPage(1)
	}

	const handleInputChange = debounceHandler(handleSearch)

	useEffect(() => {
		if (data?.data?.items && !search) {
			const existingClientValues = clientOptions?.map((option: any) => option.value)
			const newClientOptions = data?.data?.items
				.filter((item) => !existingClientValues.includes(item.id))
				.map((i) => ({
					...i,
					label: i?.title,
					value: i?.id,
				}))
			setClientSearchOptions([])
			setClientOptions((prevOptions: any) => [...prevOptions, ...newClientOptions])
		}
	}, [data?.data?.items])

	useEffect(() => {
		if (data?.data?.items && search) {
			const existingClientValues = clientSearchOptions?.map((option: any) => option.value)
			const newClientSearchOptions = data?.data?.items
				.filter((item) => !existingClientValues.includes(item.id))
				.map((i) => ({
					...i,
					label: i?.title,
					value: i?.id,
				}))
			setClientOptions([])
			setClientSearchOptions((prevOptions: any) => [...prevOptions, ...newClientSearchOptions])
		}
	}, [data?.data?.items])

	return {
		search,
		makeSearch: handleInputChange,
		isLoading,
		isFetching,
		options: search ? clientSearchOptions : clientOptions,
		refetch: refetch,
		meta: data?.data,
	}
}

interface IClientsQueryProps
	extends Partial<
			UseQueryOptions<
				IAxiosResponseWithPagination<IClient<IClientRepresentative<IHumanName>, IAddress>>,
				any
			>
		>,
		Partial<IGroupClientsParams> {}

export function useAllClients({
	page,
	limit,
	sort,
	city,
	title,
	name,
	state,
	search,
	startDate,
	endDate,
	isEnabled = true,
	...rest
}: IClientsQueryProps = {}) {
	return useQuery<
		IAxiosResponseWithPagination<IClient<IClientRepresentative<IHumanName>, IAddress>>,
		any,
		IAxiosResponseWithPagination<IClient<IClientRepresentative<IHumanName>, IAddress>>
	>(
		[ENDPOINTS.CLIENTS_ALL, page, limit, sort, city, title, name, state, search],
		() => {
			const CancelToken = axios.CancelToken
			const source = CancelToken.source()

			return getGroupClients(
				{
					page,
					limit,
					sort,
					city,
					title,
					name,
					state,
					search,
					startDate,
					endDate,
				},
				source,
			)
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: isEnabled,
			suspense: false,
			...rest,
		},
	)
}
